import { useUpdateDimStoreArticleLocalFlagMutation } from "../../utils/__generated__/graphql"
import { PaysanFalse } from "../../ui/icons/PaysanFalse"
import { PaysanTrue } from "../../ui/icons/PaysanTrue"
import { AllMercurialInfo } from "../../reducers/mercurialReducer"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { StateType } from "../../types"

interface LocalFlagToggleProps {
  firstRow: Partial<AllMercurialInfo>
  storeId: string | null
}

const LocalFlagToggle = ({ firstRow, storeId }: LocalFlagToggleProps) => {
  const [updateLocalFlag, { loading }] =
    useUpdateDimStoreArticleLocalFlagMutation()
  const [localFlag, setLocalFlag] = useState(firstRow.local_flag)
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )

  useEffect(() => {
    setLocalFlag(firstRow.local_flag)
  }, [firstRow.local_flag, firstRow.mercuriale_id])

  const handleClick = async () => {
    setLocalFlag(!localFlag)

    try {
      if (isTestMode === true) return

      const result = await updateLocalFlag({
        variables: {
          input: {
            order_id: firstRow.order_id!,
            store_id: storeId ?? "",
            sale_id: firstRow.sale_id!,
            article_name: firstRow.mercuriale_name!,
            local_flag: !localFlag,
          },
        },
      })

      if (!result.data?.updateDimStoreArticleLocalFlag.success) {
        throw new Error(
          result.data?.updateDimStoreArticleLocalFlag.error?.message,
        )
      }

      firstRow.local_flag = !localFlag
    } catch (e) {
      console.error(e)
      setLocalFlag(localFlag)
    }
  }

  return (
    <>
      <div
        className={`w-8 h-8 lg:w-10 lg:h-10 p-2 ${localFlag ? "bg-green-100" : "bg-neutral-50"} rounded-r-[5px] rounded-l-[5px] lg:rounded-l-none border-l-2 lg:border-l-0 border-r-2 border-y-2 ${localFlag ? "border-green-800" : "border-zinc-100"} justify-center items-center gap-2 inline-flex`}
      >
        <button
          onClick={handleClick}
          disabled={loading}
          className={`focus:outline-none ${loading ? "cursor-not-allowed opacity-50" : "hover:bg-opacity-75"}`}
        >
          {localFlag ? (
            <PaysanTrue className="w-5 h-5 lg:w-6 lg:h-6 relative" />
          ) : (
            <PaysanFalse className="w-5 h-5 lg:w-6 lg:h-6 relative" />
          )}
        </button>
      </div>
    </>
  )
}

export default LocalFlagToggle
