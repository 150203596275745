import { type StoreReducerState } from "../reducers/storeReducer"
import { getTotalStock } from "./getStockQuantity"

interface getOrderQuantityParams {
  backQuantity: number
  floorQuantity: number
  predictedQuantityArray: number[]
  storeSettings: StoreReducerState["storeSettings"]
}

export function getOrderQuantity({
  backQuantity,
  floorQuantity,
  predictedQuantityArray,
  storeSettings,
}: getOrderQuantityParams): number {
  const totalStock = getTotalStock({
    backQuantity,
    floorQuantity,
    storeSettings,
  })
  const index = Math.round(totalStock * 4)
  const predictedQuantity = predictedQuantityArray[index]
  return predictedQuantity ?? 0
}
