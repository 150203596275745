import { useDispatch, useSelector } from "react-redux"
import {
  GetMatchingReferencesQuery,
  useGetMatchingReferencesLazyQuery,
  useUpdateDimStoreArticleSaleIdMutation,
} from "../utils/__generated__/graphql"
import { DispatchActionType, StateType } from "../types"
import { ArrowLeftIcon } from "../ui/icons/ArrowLeftIcon"
import { Virtuoso } from "react-virtuoso"
import { Fragment, useEffect, useMemo, useState } from "react"
import { Input } from "../ui/Input"
import { SearchIcon } from "../ui/icons/SearchIcon"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import { removeDuplicates } from "../utils/removeDuplicates"
import { Combobox, Transition } from "@headlessui/react"
import { captureException } from "@sentry/react"
import { getTimeAgo } from "../utils/getTimeAgo"
import { Link } from "react-router-dom"
import { Spinner } from "../ui/Spinner"

export function MatchingPage() {
  const dispatch = useDispatch<DispatchActionType>()

  const storeId = useSelector((state: StateType) => state.storeReducer.storeId)
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )

  const [searchValue, setSearchValue] = useState("")
  const [selectedReference, setSelectedReference] = useState<{
    orderId: string
    value?: string
  }>()
  const [saleNameQueryValue, setSaleNameQueryValue] = useState("")
  const [matchingReferenceResult, setMatchingReferenceResult] =
    useState<GetMatchingReferencesQuery["getMatchingReferences"]>()

  const [getMatchingReferences, { loading }] =
    useGetMatchingReferencesLazyQuery({
      variables: {
        input: {
          store_id: storeId ?? "",
        },
      },
    })
  const [UpdateDimStoreArticleSaleIdMutation] =
    useUpdateDimStoreArticleSaleIdMutation()

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await getMatchingReferences()
        if (result.data?.getMatchingReferences.error !== null) {
          throw new Error(result.data?.getMatchingReferences.error?.message)
        }

        setMatchingReferenceResult(result?.data.getMatchingReferences)
      } catch (error) {
        console.error(error)
        captureException(error)
        dispatch({
          type: "setSnackbar",
          payload: {
            type: "error",
            message: "Aucune donnée",
          },
        })
      }
    }
    fetchData()
  }, [dispatch, getMatchingReferences])

  const filteredReferences = useMemo(() => {
    return matchingReferenceResult?.matching_references
      ?.filter(
        (_reference) =>
          _reference.article_name
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          _reference.order_code?.includes(searchValue),
      )
      .sort((a, b) => {
        return a.article_name.localeCompare(b.article_name)
      })
  }, [matchingReferenceResult?.matching_references, searchValue])

  const saleNames = useMemo(() => {
    const _saleNames = matchingReferenceResult?.matching_references?.map(
      (_reference) => _reference.sale_name_ida,
    )
    if (saleNameQueryValue === "") return []
    return removeDuplicates(
      _saleNames?.filter((saleName) => saleName.includes(saleNameQueryValue)) ??
        [],
    )
  }, [matchingReferenceResult?.matching_references, saleNameQueryValue])

  return (
    <div className="p-8 h-screen flex flex-col gap-2 text-zinc-800">
      <div className="flex items-center gap-8 mb-4">
        <Link to="/account/calendar">
          <ArrowLeftIcon className="w-6 h-6" />
        </Link>
        <p className="text-2xl text-black font-bold">Matching</p>
      </div>
      <div className="flex items-center justify-between">
        <Input
          name="search"
          type="text"
          placeholder="Rechercher"
          icon={<SearchIcon className="w-4 h-4 lg:w-6 lg:h-6" />}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {typeof matchingReferenceResult?.last_update === "string" && (
          <p className="text-zinc-500 text-sm">
            Dernière modification :{" "}
            {getTimeAgo(new Date(matchingReferenceResult.last_update))}
          </p>
        )}
      </div>
      <div className="grid grid-cols-3 bg-zinc-100 p-2 rounded font-bold text-sm">
        <p>Nom</p>
        <p>Code de commande</p>
        <p>Code de vente</p>
      </div>
      {loading && (
        <div className="flex justify-center">
          <Spinner invertColors className="w-6" />
        </div>
      )}
      <Virtuoso
        className="h-full"
        data={filteredReferences}
        itemContent={(_, reference) => (
          <div>
            <div className="grid grid-cols-3 items-center gap-4 py-2 px-4">
              <p className="font-bold">{reference.article_name}</p>
              <p>{reference.order_code}</p>
              <Combobox
                value={
                  selectedReference?.orderId === reference.order_id
                    ? selectedReference.value
                    : reference.sale_name_ida
                }
                onChange={async (value) => {
                  if (value === reference.sale_name_ida) return
                  setSelectedReference({ orderId: reference.order_id, value })
                  setSaleNameQueryValue("")

                  if (isTestMode) return
                  try {
                    const result = await UpdateDimStoreArticleSaleIdMutation({
                      variables: {
                        input: {
                          order_id: reference.order_id,
                          store_id: storeId!,
                          sale_name: value,
                        },
                      },
                    })
                    if (
                      result.data?.updateDimStoreArticleSaleId.error !== null
                    ) {
                      throw new Error(
                        result.data?.updateDimStoreArticleSaleId.error?.message,
                      )
                    }

                    setMatchingReferenceResult((_matchingReferenceResult) => {
                      // Create new array to avoid mutating old one
                      const updatedReferences = [
                        ...(_matchingReferenceResult?.matching_references ??
                          []),
                      ]
                      const referenceIndex = updatedReferences.findIndex(
                        (_reference) =>
                          _reference.order_id === reference.order_id,
                      )
                      updatedReferences[referenceIndex] = {
                        ...updatedReferences[referenceIndex],
                        sale_name_ida: value,
                      }

                      return {
                        ..._matchingReferenceResult,
                        last_update: new Date().toISOString(),
                        matching_references: updatedReferences,
                      }
                    })
                  } catch (error) {
                    console.error(error)
                    captureException(error)
                    dispatch({
                      type: "setSnackbar",
                      payload: {
                        type: "error",
                        message: "Données non sauvegardées",
                      },
                    })
                  }
                }}
              >
                <div className="relative">
                  <div className="relative w-full cursor-default overflow-hidden rounded text-left shadow focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                    <Combobox.Input
                      className="w-full bg-transparent rounded p-2 pr-10 border-2 border-zinc-400 focus:border-green-800 text-gray-900"
                      displayValue={(saleName) => saleName as string}
                      onChange={(event) =>
                        setSaleNameQueryValue(event.target.value)
                      }
                      placeholder="Aucun code de vente"
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Combobox.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setSaleNameQueryValue("")}
                  >
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white p-4 shadow focus:outline-none">
                      <p className="text-zinc-500 font-medium px-2">
                        Sélectionnez un code
                      </p>
                      {saleNames.length === 0 && saleNameQueryValue !== "" ? (
                        <p className="relative cursor-default select-none px-2 py-1 text-gray-700">
                          Aucun résultat
                        </p>
                      ) : (
                        <>
                          <Combobox.Option
                            className={({ active }) =>
                              `relative cursor-pointer select-none rounded px-2 py-1 ${active ? "bg-gray-100" : ""}`
                            }
                            value={reference.sale_name_ida}
                          >
                            <span className="block truncate w-fit border border-neutral-200 bg-zinc-100 px-2 rounded font-medium">
                              {reference.sale_name_ida}
                            </span>
                          </Combobox.Option>
                          {saleNames
                            .filter(
                              (saleName) =>
                                saleName !== reference.sale_name_ida,
                            )
                            .slice(0, 4)
                            .map((saleName, i) => (
                              <Combobox.Option
                                key={i}
                                className={({ active }) =>
                                  `relative cursor-pointer select-none rounded px-2 py-1 ${active ? "bg-gray-100" : ""}`
                                }
                                value={saleName}
                              >
                                <span className="block truncate w-fit font-medium">
                                  {saleName}
                                </span>
                              </Combobox.Option>
                            ))}
                        </>
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
            </div>
            <hr />
          </div>
        )}
      />
    </div>
  )
}
