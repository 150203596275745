import { twMerge } from "tailwind-merge"

interface IconType {
  className?: string
}

export function AirPlaneIcon({ className }: IconType): JSX.Element {
  const mergedClassName = twMerge("w-6 h-6", className)

  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={mergedClassName}
    >
      <path
        d="M5.99955 12L3.26855 3.12601C9.80136 5.02568 15.9619 8.0268 21.4846 12C15.9623 15.9738 9.80213 18.9756 3.26955 20.876L5.99955 12ZM5.99955 12H13.4996"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
