import { useSearchParams } from "react-router-dom"
import { StoreReducerState } from "../../reducers/storeReducer"
import { formatValue } from "./utils"
import { GroupedReferences } from "."
import { usePotentialRevenue } from "../../hooks/usePotentialRevenue"

interface GroupContentProps {
  storeStoreSuppliers: StoreReducerState["storeStoreSuppliers"]
  storeCurrency: StoreReducerState["storeCurrency"]
  supplierData: GroupedReferences
}

export function GroupContent({
  supplierData,
  storeCurrency,
  storeStoreSuppliers,
}: GroupContentProps) {
  const [searchParams] = useSearchParams()
  const { totalOrders, potentialCommandRevenue, orderPrice } =
    usePotentialRevenue({ references: supplierData.products })

  const orderId = searchParams.get("orderId")
  const currentWeekday = Intl.DateTimeFormat("fr-FR", {
    weekday: "long",
  }).format(new Date())
  const minimalOrderValue =
    storeStoreSuppliers?.find(
      (storeSupplier) =>
        storeSupplier.supplier_id === supplierData.supplierId &&
        storeSupplier.order_day === currentWeekday,
    )?.minimal_order_value ?? undefined
  const isOrderValueHighlighted =
    minimalOrderValue !== undefined &&
    (orderPrice ?? 0) < minimalOrderValue &&
    (typeof orderId !== "string" || orderId === "")

  return (
    <div
      className={`border-gray-200 bg-[#E2E2E2] col-span-6 grid grid-cols-6 p-2 gap-4 text-xs md:text-lg lg:text-xl`}
    >
      <p className="col-span-6 md:col-span-3 text-left font-semibold text-gray-900 truncate flex items-center gap-4">
        {supplierData.supplierName}
        {minimalOrderValue !== undefined &&
          minimalOrderValue > 0 &&
          (typeof orderId !== "string" || orderId === "") && (
            <span className="text-xs md:text-base lg:text-lg font-normal text-gray-500">
              Franco :{" "}
              <span className="text-xs md:text-sm lg:text-base">
                {formatValue(minimalOrderValue, storeCurrency)}
              </span>
            </span>
          )}
      </p>
      <p className="hidden md:block col-start-4 col-end-4 font-semibold text-gray-900 text-center">
        {totalOrders} cs
      </p>
      <p
        className={`hidden md:block col-span-1 col-start-5 col-end-5 text-lg font-semibold ${isOrderValueHighlighted ? "text-red-500" : "text-gray-900"} text-center`}
      >
        {formatValue(orderPrice ?? 0, storeCurrency)}
      </p>
      <p className="hidden md:block col-start-6 col-end-6 font-semibold text-gray-900 text-center">
        {formatValue(potentialCommandRevenue ?? 0, storeCurrency)}
      </p>
    </div>
  )
}
