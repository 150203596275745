import { Button } from "@/ui/Button"
import { useCreateUserMutation, User } from "@/utils/__generated__/graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import * as z from "zod"
import { useForm } from "react-hook-form"
import { captureException } from "@sentry/react"
import { useDispatch } from "react-redux"
import { DispatchActionType } from "@/types"

const formSchema = z.object({
  email: z
    .string()
    .email({ message: "Invalid email address" })
    .trim()
    .toLowerCase(),
  password: z
    .string()
    .min(8, { message: "Password must be at least 8 characters" })
    .regex(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])/,
      "Password must contain at least one number and one special character",
    ),
  store_id: z.string().min(1, { message: "Store ID is required" }),
  company_name: z.string().min(1, { message: "Company name is required" }),
  store_name: z.string().min(1, { message: "Store name is required" }),
  franchise_name: z.string().optional().nullable().default(null),
})

interface FormProps {
  handleFormSubmit: (user: User) => void
}

type FormData = z.infer<typeof formSchema>

export const UserForm = ({ handleFormSubmit }: FormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
  })
  const [createUser] = useCreateUserMutation()
  const dispatch = useDispatch<DispatchActionType>()
  const onSubmit = async (data: FormData) => {
    try {
      await createUser({
        variables: {
          input: { ...data },
        },
      })
      handleFormSubmit({
        email: data.email,
        store_id: data.store_id,
        company_name: data.company_name,
        store_name: data.store_name,
      })
    } catch (error) {
      console.error(error)
      captureException(error)
      dispatch({
        type: "setSnackbar",
        payload: {
          type: "error",
          message: "Failed to create user, check store_id or email",
        },
      })
    }
  }
  type FormKeys = keyof typeof formSchema.shape

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-6 w-full max-w-md"
    >
      <div className="grid gap-4 py-4">
        {Object.keys(formSchema.shape).map((key) => (
          <div key={key} className="mb-3 grid grid-cols-4 items-center gap-4">
            <label
              color="black"
              htmlFor={key}
              className="form-label font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-right"
            >
              {key.replace("_", " ")}
            </label>
            <div className="relative">
              <input
                {...register(key as FormKeys)}
                id={key}
                placeholder={key.replace("_", " ")}
                name={key}
                className="form-control h-9 rounded-md border border-input transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                type={key === "password" ? "password" : "text"}
              />
              {errors[key as FormKeys] && (
                <span className="text-red-500 text-sm">
                  <p>{errors?.[key as FormKeys]?.message}</p>
                </span>
              )}
            </div>
          </div>
        ))}
        <Button disabled={!isValid} type="submit" className="w-full">
          Create
        </Button>
      </div>
    </form>
  )
}
