import { useDispatch } from "react-redux"
import { Button } from "../../ui/Button"
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline"
import {
  InventoryType,
  useBatchUpdateMutation,
  useLogsModificationUpdateMutation,
} from "../../utils/__generated__/graphql"
import { captureException } from "@sentry/react"
import { MercurialReducerState } from "../../reducers/mercurialReducer"
import { StoreReducerState } from "../../reducers/storeReducer"
import { GroupedReferences } from "."
import { DispatchActionType } from "../../types"
import { DataSynchronizationStatus } from "../../reducers/connectionReducer"
import { computeModificationObject } from "../../utils/sendLogsModificationUpdate"

const INCREMENT_QUANTITY = "INCREMENT_QUANTITY"
const DECREMENT_QUANTITY = "DECREMENT_QUANTITY"

interface TableCellProps {
  handleQuantity?: boolean
  product: GroupedReferences["products"][number]
  dimMercurialeId?: MercurialReducerState["mercurialAndStoreInventories"][number]["dim_mercuriale_id"]
  dimOrderRequestId?: Exclude<
    MercurialReducerState["dimMercuriales"],
    undefined
  >[number]["dimOrderRequestId"]
  storeId?: StoreReducerState["storeId"]
  isOnline: boolean
  isTestMode?: boolean
  storeSettings: StoreReducerState["storeSettings"]
}

// Store all timeouts in a global variable with mercuriale id as key (each timeout will be deleted at the end of the function)
const timeouts: Record<string, NodeJS.Timeout> = {}

const OrderedQuantityCell = ({
  handleQuantity,
  product,
  dimMercurialeId,
  dimOrderRequestId,
  storeId,
  isOnline,
  isTestMode,
  storeSettings,
}: TableCellProps) => {
  const dispatch = useDispatch<DispatchActionType>()
  const [batchUpdateMutation] = useBatchUpdateMutation()
  const [logsModificationUpdate] = useLogsModificationUpdateMutation()

  const handleClick = (
    type: typeof INCREMENT_QUANTITY | typeof DECREMENT_QUANTITY,
  ) => {
    if (!("mercuriale_id" in product) || !("order_id" in product)) return
    const quantity = product.quantity_actual ?? 0
    if (typeof product.mercuriale_id !== "string") return
    if (type === DECREMENT_QUANTITY && quantity <= 0) return
    const colisage =
      storeSettings?.use_kg_pce === true ? (product.colisage ?? 1) : 1
    const value =
      type === INCREMENT_QUANTITY ? quantity + colisage : quantity - colisage

    dispatch({
      type: "updateReference",
      payload: {
        mercurialeId: product.mercuriale_id ?? "",
        orderInventoryQuantity: value,
        isOrderInventoryQuantityUpdated: true,
      },
    })

    clearTimeout(timeouts[product.mercuriale_id])
    timeouts[product.mercuriale_id] = setTimeout(() => {
      const saveModifications = async (): Promise<void> => {
        const modification = computeModificationObject({
          inventoryType: InventoryType.Order,
          mercurialeInfo: product,
          storeSettings: storeSettings ?? {},
          modifiedValue: value,
        })

        try {
          const batchUpdateResult = await batchUpdateMutation({
            variables: {
              input: {
                batch_data: [
                  {
                    order_id: product.order_id,
                    mercuriale_id: product.mercuriale_id!,
                    quantity_actual: value,
                    back_inventory: null, // We don't need back_inventory
                    floor_inventory: null, // We don't need floor_inventory
                    shelf_floor_size: null, // We don't need shelf_floor_size
                    colisage: product.colisage,
                    dim_mercuriale_id: dimMercurialeId,
                    sale_id: null, // We don't need sale_id to update quantity_actual
                  },
                ],
                dim_order_request_id: dimOrderRequestId,
                store_id: storeId ?? "",
                inventory_type: InventoryType.Order,
              },
            },
          })
          if (batchUpdateResult.data?.batchUpdate?.error !== null) {
            captureException(batchUpdateResult.data?.batchUpdate?.error)
            return
          }
          if (
            typeof batchUpdateResult.data.batchUpdate.dim_order_request_id ===
            "string"
          ) {
            dispatch({
              type: "setDimOrderRequestId",
              payload: {
                dimOrderRequestId:
                  batchUpdateResult.data?.batchUpdate.dim_order_request_id,
                dimMercurialeId: product.dim_mercuriale_id ?? "",
              },
            })
          }

          const logsModificationResult = await logsModificationUpdate({
            variables: {
              input: {
                modifications_logs_items: [modification],
                store_id: storeId!,
              },
            },
          })
          if (
            logsModificationResult.data?.logsModificationUpdate?.error !== null
          ) {
            throw logsModificationResult.data?.logsModificationUpdate?.error
          }
        } catch (error) {
          console.error(error)
          const errorMesssage =
            error instanceof Error ? error.message : "Données non sauvegardées"
          captureException(new Error(errorMesssage))
          dispatch({
            type: "setDataSynchronizationStatus",
            payload: DataSynchronizationStatus.FAILURE,
          })
          dispatch({
            type: "addModification",
            payload: modification,
          })
        }
      }
      if (isOnline && !isTestMode) {
        void saveModifications()
      } else {
        dispatch({
          type: "setDataSynchronizationStatus",
          payload: DataSynchronizationStatus.UNSYNCHRONIZED,
        })
      }
      // Delete timeout to avoid memory leaks
      delete timeouts[product.mercuriale_id!]
    }, 1000)
  }

  const buttonClassName = "w-6 h-6 lg:w-8 lg:h-8 rounded-full p-0"

  return (
    <div className="flex gap-1 md:gap-4 justify-center items-center md:text-xl">
      {handleQuantity && "active" in product && (
        <Button
          disabled={
            product.active === false || (product.quantity_actual ?? 0) <= 0
          }
          onClick={() => handleClick(DECREMENT_QUANTITY)}
          className={buttonClassName}
        >
          <MinusIcon className="w-4 h-4" />
        </Button>
      )}
      <p>
        <span className="w-full">
          <span className="w-1/2 text-right">
            {storeSettings?.use_kg_pce === true
              ? (product.quantity_actual ?? 0) / (product.colisage ?? 1)
              : (product.quantity_actual ?? 0)}
          </span>
          <span className="ml-1 w-1/2">cs</span>
        </span>
      </p>
      {handleQuantity && "active" in product && (
        <Button
          disabled={product.active === false}
          onClick={() => handleClick(INCREMENT_QUANTITY)}
          className={buttonClassName}
        >
          <PlusIcon className="w-4 h-4" />
        </Button>
      )}
    </div>
  )
}

export default OrderedQuantityCell
