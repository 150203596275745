import { motion } from "framer-motion"
import { pageTransition } from "../utils/__generated__/transition"
import Header from "../components/Header"
import { Outlet } from "react-router-dom"
import ContactUsBar from "../components/ContactUsBar"

const Account = () => {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exitReverse"
      variants={pageTransition}
      className="w-full h-screen flex flex-col justify-between"
    >
      <Header />
      <Outlet />
      <hr />
      <ContactUsBar />
    </motion.div>
  )
}

export default Account
