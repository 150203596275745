import { getQuarterValue } from "../../utils/getQuarterValue"

interface SaleRowProps {
  saleHistoricalQuantity: number | undefined
}

export function SaleRow({ saleHistoricalQuantity }: SaleRowProps) {
  return (
    <p className="w-full flex justify-center items-center gap-1">
      {saleHistoricalQuantity !== undefined ? (
        <>
          {(getQuarterValue(saleHistoricalQuantity).value ?? 0) > 0 ||
          getQuarterValue(saleHistoricalQuantity).decimal === undefined
            ? getQuarterValue(saleHistoricalQuantity).value
            : null}
          {getQuarterValue(saleHistoricalQuantity, "w-4 h-4").icon}
        </>
      ) : (
        "-"
      )}
    </p>
  )
}
