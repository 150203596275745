import { useCallback, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { type DispatchActionType, type StateType } from "../types"
import { getOrderQuantity } from "../utils/getOrderQuantity"
import { type MercurialReducerState } from "../reducers/mercurialReducer"
import { useResetOrderQuantityMutation } from "../utils/__generated__/graphql"
import { captureException } from "@sentry/react"

interface ReloadProps {
  data: MercurialReducerState["mercurialAndStoreInventories"][number]
  disabled?: boolean
}

const Reload = ({ data, disabled = false }: ReloadProps): JSX.Element => {
  const dispatch = useDispatch<DispatchActionType>()
  const { storeId, storeSettings } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  const { enable } = useSelector(
    (state: StateType) => state.trainingModeReducer,
  )
  const dimMercuriales = useSelector(
    (state: StateType) => state.mercurialReducer.dimMercuriales,
  )
  const dimOrderRequestId = dimMercuriales?.find(
    (dimMercuriale) => dimMercuriale.dimMercurialeId === data.dim_mercuriale_id,
  )?.dimOrderRequestId

  const [resetOrderQuantityMutation] = useResetOrderQuantityMutation()

  const isDisabled = useMemo(() => {
    if (
      typeof data?.mercuriale_id !== "string" ||
      typeof dimOrderRequestId !== "string" ||
      disabled
    )
      return true

    const orderQuantity = getOrderQuantity({
      backQuantity: data?.back_inventory_qty ?? 0,
      floorQuantity: data?.floor_inventory_qty ?? 0,
      predictedQuantityArray: data?.quantity_predicted_array ?? [],
      storeSettings,
    })
    if (
      orderQuantity >= 0 &&
      data?.quantity_actual !== orderQuantity &&
      data?.quantity_predicted_array !== null &&
      online
    ) {
      return false
    }
    return true
  }, [
    data?.back_inventory_qty,
    data?.floor_inventory_qty,
    data?.mercuriale_id,
    data?.quantity_actual,
    data?.quantity_predicted_array,
    online,
    dimOrderRequestId,
    disabled,
    storeSettings,
  ])

  const transformStyle = useMemo(() => {
    return isDisabled
      ? { transform: "translateX(-180%)" }
      : { transform: "translateX(70%)" }
  }, [isDisabled])

  const handleClick = useCallback(async (): Promise<void> => {
    if (
      typeof data?.mercuriale_id !== "string" ||
      typeof dimOrderRequestId !== "string" ||
      typeof storeId !== "string"
    )
      return
    const orderQuantity = getOrderQuantity({
      backQuantity: data?.back_inventory_qty ?? 0,
      floorQuantity: data?.floor_inventory_qty ?? 0,
      predictedQuantityArray: data?.quantity_predicted_array ?? [],
      storeSettings,
    })

    const updatedReferencePayload = {
      mercurialeId: data.mercuriale_id,
      orderInventoryQuantity: orderQuantity,
      isOrderInventoryQuantityUpdated: false,
    }

    dispatch({
      type: "updateReference",
      payload: updatedReferencePayload,
    })
    dispatch({
      type: "setSelectedInventory",
      payload: {
        type: undefined,
        mercurialeId: undefined,
      },
    })

    if (enable) return

    try {
      const resetOrderQuantityResult = await resetOrderQuantityMutation({
        variables: {
          input: {
            dim_order_request_id: dimOrderRequestId,
            store_id: storeId,
            mercuriale_id: data.mercuriale_id,
          },
        },
      })
      if (resetOrderQuantityResult.data?.resetOrderQuantity?.error !== null) {
        captureException(
          resetOrderQuantityResult.data?.resetOrderQuantity?.error,
        )
        return
      }
    } catch (error) {
      console.error(error)
      captureException(error)
      dispatch({
        type: "setSnackbar",
        payload: {
          type: "error",
          message: "Données non sauvegardées",
        },
      })
    }
  }, [
    data?.back_inventory_qty,
    data?.floor_inventory_qty,
    data.mercuriale_id,
    data?.quantity_predicted_array,
    dimOrderRequestId,
    dispatch,
    enable,
    resetOrderQuantityMutation,
    storeId,
    storeSettings,
  ])

  return (
    <div className="h-full flex items-center">
      <button
        disabled={isDisabled}
        onClick={() => {
          void handleClick()
        }}
        className="flex w-9 h-9 transition ease-in duration-200 items-center justify-center mb-2 bg-white rounded-full shadow hover:bg-zinc-100"
        style={transformStyle}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
        >
          <g clipPath="url(#clip0_14_30341)">
            <path
              d="M0.708252 2.83333V7.08333M0.708252 7.08333H4.95825M0.708252 7.08333L3.99492 3.995C4.97307 3.01851 6.24207 2.38635 7.61073 2.19379C8.97939 2.00123 10.3736 2.25869 11.5832 2.92737C12.7928 3.59606 13.7523 4.63976 14.3172 5.9012C14.8821 7.16264 15.0217 8.57349 14.715 9.92117C14.4083 11.2689 13.6719 12.4804 12.6168 13.3732C11.5617 14.266 10.2451 14.7917 8.86522 14.8711C7.48536 14.9505 6.11707 14.5793 4.96651 13.8135C3.81595 13.0476 2.94545 11.9286 2.48617 10.625"
              stroke="#9E9E9E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_14_30341">
              <rect width="17" height="17" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  )
}

export default Reload
