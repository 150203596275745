import { useCallback, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AlertTriangleIcon } from "../AlertTriangle"
import UrgentAlertTriangle from "../UrgentAlertTriangle"
import { DispatchActionType, type StateType } from "../../types"
import { AllMercurialInfo } from "../../reducers/mercurialReducer"
import { Button } from "../../ui/Button"
import { usePotentialRevenue } from "../../hooks/usePotentialRevenue"
import { Spinner } from "../../ui/Spinner"
import { WifiAlertIcon } from "../../ui/AlertIcons"
import { Modal } from "../../ui/Modal"
import { ArrowPathIcon, CloudIcon } from "@heroicons/react/24/outline"

interface ConfirmRecapModalProps {
  setOpen: (open: boolean) => void
  open: boolean
  isLoading: boolean
  handleConfirm: (isWaitingSend: boolean) => void
  excessiveProducts: AllMercurialInfo[]
  boxProducts: AllMercurialInfo[]
  isWaitingSend: boolean
  setIsWaitingSend: (isWaitingSend: boolean) => void
}

const ConfirmRecapModal = ({
  setOpen,
  open,
  isLoading,
  handleConfirm,
  excessiveProducts,
  boxProducts,
  isWaitingSend,
  setIsWaitingSend,
}: ConfirmRecapModalProps): JSX.Element => {
  const dispatch = useDispatch<DispatchActionType>()
  const [hasAcknowledgedBox, setHasAcknowledgedBox] = useState(false)
  const [hasAcknowledgedExcess, setHasAcknowledgedExcess] = useState(false)
  const [isHealthLoading, setIsHealthLoading] = useState(false)

  const { storeCurrency } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const { enable } = useSelector(
    (state: StateType) => state.trainingModeReducer,
  )
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )

  const { totalOrders, orderPrice } = usePotentialRevenue()

  const retryConnection = useCallback(async () => {
    try {
      setIsHealthLoading(true)
      const response = await fetch(
        import.meta.env.VITE_API_URL.replace("/graphql/", "/health"),
      )
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`)
      }
      const data = (await response.json()) as { status: string }
      if (data.status !== "ok") {
        throw new Error(`Response status: ${data.status}`)
      }
      dispatch({
        type: "setOnline",
        payload: true,
      })
      dispatch({
        type: "setSnackbar",
        payload: {
          type: "success",
          message: "Connexion internet rétablie",
          icon: <CloudIcon className="w-6 h-6" />,
        },
      })
    } catch (error) {
      console.error(error)
      dispatch({
        type: "setSnackbar",
        payload: {
          type: "error",
          message: "Aucune connexion internet",
          icon: <CloudIcon className="w-6 h-6" />,
        },
      })
    } finally {
      setIsHealthLoading(false)
    }
  }, [dispatch])

  const handleClose = useCallback((): void => {
    if (isLoading) return
    setTimeout(() => {
      setHasAcknowledgedBox(false)
      setHasAcknowledgedExcess(false)
      setIsWaitingSend(false)
    }, 250) // to avoid seeing the false value while closing window
    setOpen(false)
  }, [isLoading, setIsWaitingSend, setOpen])

  function Icon() {
    return excessiveProducts.length > 0 && !hasAcknowledgedExcess ? (
      <UrgentAlertTriangle />
    ) : isWaitingSend ? (
      <WifiAlertIcon />
    ) : (
      <AlertTriangleIcon />
    )
  }

  const title =
    boxProducts.length > 0 && !hasAcknowledgedBox
      ? "Des boxes sont présentes dans votre commande :"
      : excessiveProducts.length > 0 && !hasAcknowledgedExcess
        ? "Ces quantités sont plus de trois fois supérieures à la valeur recommandée. Êtes-vous certain de vouloir continuer ?"
        : isWaitingSend
          ? "Vous n'êtes pas connecté à internet."
          : "Voulez-vous vraiment envoyer cette commande ?"

  const content = useMemo(() => {
    if (boxProducts.length > 0 && !hasAcknowledgedBox) {
      return (
        <>
          <ul className="text-center mx-auto my-4 max-w-[90%] overflow-y-auto max-h-40">
            {boxProducts.map((item, index) => (
              <li key={index}>
                <p>
                  {item.mercuriale_name} -{" "}
                  <span className="font-bold">{item.quantity_actual} cs</span>
                </p>
              </li>
            ))}
          </ul>
          <div className="flex items-center gap-3 mt-8 justify-center">
            <Button
              onClick={handleClose}
              className="w-full h-[44px] text-[16px]"
              color="ghost"
            >
              Annuler
            </Button>
            <Button
              onClick={() => setHasAcknowledgedBox(true)}
              className="w-full h-[44px] text-[16px]"
              color="red"
            >
              Confirmer
            </Button>
          </div>
        </>
      )
    }

    if (excessiveProducts.length > 0 && !hasAcknowledgedExcess) {
      return (
        <>
          <ul className="text-center mx-auto my-4 max-w-[90%] overflow-y-auto max-h-40">
            {excessiveProducts.map((item, index) => (
              <li key={index}>
                <p>
                  {item.mercuriale_name} -{" "}
                  <span className="font-bold">{item.quantity_actual} cs</span>
                </p>
              </li>
            ))}
          </ul>
          <div className="flex items-center gap-3 mt-8 justify-center">
            <Button
              onClick={handleClose}
              className="w-full h-[44px] text-[16px]"
              color="ghost"
            >
              Annuler
            </Button>
            <Button
              onClick={() => setHasAcknowledgedExcess(true)}
              className="w-full h-[44px] text-[16px]"
              color="red"
            >
              Confirmer
            </Button>
          </div>
        </>
      )
    }

    if (
      (hasAcknowledgedExcess || excessiveProducts.length === 0) &&
      !isWaitingSend
    ) {
      return (
        <>
          <p className="text-[#667085] text-[14px] font-normal text-center">
            Coût total -{" "}
            {new Intl.NumberFormat("fr-FR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
              style: "currency",
              currency: storeCurrency ?? "EUR",
            }).format(orderPrice ?? 0)}
            <br />
            Nb de caisses total -{" "}
            {new Intl.NumberFormat("fr-FR", {
              maximumFractionDigits: 0,
            }).format(totalOrders ?? 0)}
          </p>
          <div className="flex items-center gap-3 mt-8 justify-center">
            <Button
              onClick={handleClose}
              className="w-full h-[44px] text-[16px]"
              color="ghost"
              disabled={isLoading}
            >
              Annuler
            </Button>
            <Button
              color={enable ? "yellow" : "green"}
              onClick={() => {
                void handleConfirm(!online)
              }}
              className="w-full h-[44px] text-[16px]"
              loading={isLoading}
            >
              Envoyer
            </Button>
          </div>
        </>
      )
    }

    if (isWaitingSend) {
      return (
        <>
          <p className="text-[#667085] text-[14px] font-normal text-center">
            La commande sera envoyée automatiquement une fois la connexion
            rétablie.
          </p>
          <div className="flex items-center gap-3 mt-8 justify-center">
            {!isLoading && !isHealthLoading ? (
              <div className="w-full flex flex-col justify-center items-center gap-3">
                <button
                  onClick={retryConnection}
                  className="w-fit flex justify-center items-center gap-2"
                >
                  <ArrowPathIcon className="w-5 h-5" />
                  Réessayer de se connecter
                </button>
                <Button
                  onClick={handleClose}
                  className="w-full h-[44px]"
                  color="ghost"
                >
                  Annuler
                </Button>
              </div>
            ) : (
              <Spinner />
            )}
          </div>
        </>
      )
    }

    return <></>
  }, [
    boxProducts,
    enable,
    excessiveProducts,
    handleClose,
    handleConfirm,
    hasAcknowledgedBox,
    hasAcknowledgedExcess,
    isHealthLoading,
    isLoading,
    isWaitingSend,
    online,
    orderPrice,
    retryConnection,
    storeCurrency,
    totalOrders,
  ])

  return (
    <Modal
      title={title}
      icon={<Icon />}
      open={open}
      onClose={handleClose}
      hideCloseButton
      className="w-full max-w-md rounded-2xl"
    >
      {content}
    </Modal>
  )
}

export default ConfirmRecapModal
