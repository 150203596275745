import { formatValue } from "./utils"
import { StoreReducerState } from "../../reducers/storeReducer"
import { usePotentialRevenue } from "../../hooks/usePotentialRevenue"
import { AllMercurialInfo } from "../../reducers/mercurialReducer"
import { GetOrderItem } from "../../utils/__generated__/graphql"

interface TotalDisplayProps {
  storeCurrency: StoreReducerState["storeCurrency"]
  references: (AllMercurialInfo | GetOrderItem)[]
}

const TotalDisplay = ({ storeCurrency, references }: TotalDisplayProps) => {
  const { totalOrders, potentialCommandRevenue, orderPrice } =
    usePotentialRevenue({ references })

  return (
    <div
      className={`flex flex-col md:grid md:grid-cols-6 bg-white shadow rounded gap-4 p-4 lg:items-center text-base lg:text-lg`}
    >
      <p
        className={`col-span-1 flex items-end md:justify-center border-b border-neutral-200 md:border-b-0 text-black font-black`}
      >
        TOTAL
      </p>
      <div
        className={`flex justify-between md:grid md:col-span-5 md:grid-cols-5`}
      >
        <p
          className={`md:col-start-3 flex flex-col justify-between text-right md:text-center`}
        >
          <span className="text-xs lg:text-base">Colis</span>
          <span className="text-black text-sm md:text-base font-black">
            {totalOrders} cs
          </span>
        </p>
        <p className="md:col-start-4 flex flex-col justify-between text-right md:text-center">
          <span className="text-xs lg:text-base">
            Coût total <span className="text-xxs xs:text-xs">(HT)</span>
          </span>
          <span className="text-black text-sm md:text-base font-black">
            {formatValue(orderPrice ?? 0, storeCurrency)}
          </span>
        </p>
        <p className="md:col-start-5 flex flex-col justify-between text-right md:text-center">
          <span className="text-xs lg:text-base">
            CA Potentiel <span className="text-xxs xs:text-xs">(TTC)</span>
          </span>
          <span className="text-black text-sm md:text-base font-black">
            {formatValue(potentialCommandRevenue ?? 0, storeCurrency)}
          </span>
        </p>
      </div>
    </div>
  )
}

export default TotalDisplay
