import { BaseInventory } from "./BaseInventory"
import { InventoryType } from "../../utils/__generated__/graphql"

interface OrderInventoryProps {
  mercurialeId: string
  unit: string
  prediction_uncertain?: boolean | undefined | null
  value: number | undefined
  disabled: boolean
  className?: string
  updateInventory: (value: string) => Promise<void>
  minimal?: boolean
  isFirstRow?: boolean
  isPrecommande?: boolean
  waitedQuantityOrdered?: number
  promoWaitedQuantityOrdered?: number
}

const OrderInventory = ({
  isFirstRow,
  prediction_uncertain,
  mercurialeId,
  unit,
  value,
  disabled,
  className,
  updateInventory,
  minimal = false,
  isPrecommande = false,
  waitedQuantityOrdered,
  promoWaitedQuantityOrdered,
}: OrderInventoryProps): JSX.Element => {
  return (
    <BaseInventory
      isFirstRow={isFirstRow}
      prediction_uncertain={prediction_uncertain}
      mercurialeId={mercurialeId}
      unit={unit}
      value={value ?? 0}
      name={InventoryType.Order}
      disabled={disabled}
      className={className}
      updateInventory={updateInventory}
      minimal={minimal}
      isPrecommande={isPrecommande}
      waitedQuantityOrdered={waitedQuantityOrdered}
      promoWaitedQuantityOrdered={promoWaitedQuantityOrdered}
    />
  )
}

export default OrderInventory
