import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import MinimalLogo from "../../assets/MinimalLogo"
import FLBar from "../FIlterBar/FLBar"
import {
  ChevronDownIcon,
  ExclamationTriangleIcon,
  WifiIcon,
} from "@heroicons/react/24/outline"
import {
  removeDuplicates,
  removeDuplicatesValues,
} from "../../utils/removeDuplicates"
import { type DispatchActionType, type StateType } from "../../types"
import { Link } from "react-router-dom"
import { Spinner } from "../../ui/Spinner"
import { type PageName } from "../../reducers/userInterfaceReducer"
import { usePotentialRevenue } from "../../hooks/usePotentialRevenue"
import { StoreReducerState } from "../../reducers/storeReducer"
import { filteredMercurialeReducerSelector } from "../../selectors/mercurialeSelectors"
import { DataSynchronizationStatus } from "../../reducers/connectionReducer"
import { twJoin } from "tailwind-merge"
import { usePermissions } from "../../hooks/usePermissions"

interface OrderPriceProps {
  minimalOrderValue: number | undefined
  storeCurrency: StoreReducerState["storeCurrency"]
  storeSupplierId: string
}

function OrderPrice({
  minimalOrderValue,
  storeCurrency,
  storeSupplierId,
}: OrderPriceProps) {
  const { orderPrice } = usePotentialRevenue({
    suppliersIds: [storeSupplierId],
  })

  if ((orderPrice ?? 0) <= 0) {
    return <></>
  }

  return (
    <p
      className={`ml-2 ${(orderPrice ?? 0) < (minimalOrderValue ?? 0) ? "text-red-200" : "text-green-200"}`}
    >
      {new Intl.NumberFormat("fr-FR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        style: "currency",
        currency: storeCurrency ?? "EUR",
      }).format(orderPrice ?? 0)}
    </p>
  )
}

interface InventoryNavProps {
  isLoading: boolean
  page: PageName
  onRefreshButtonClick: () => void
  setSaveDataModalState: Dispatch<
    SetStateAction<{
      isOpen: boolean
      backToHome: boolean
    }>
  >
}

const InventoryNav = ({
  isLoading,
  page,
  setSaveDataModalState,
}: InventoryNavProps): JSX.Element => {
  const dispatch = useDispatch<DispatchActionType>()
  const { isReadOnly } = usePermissions()

  const {
    mercurialAndStoreInventories,
    selectedDimMercurialeId,
    dimMercuriales,
  } = useSelector(filteredMercurialeReducerSelector)
  const { enable } = useSelector(
    (state: StateType) => state.trainingModeReducer,
  )
  const {
    storeSettings,
    storeSuppliers,
    companyId,
    storeStoreSuppliers,
    storeCurrency,
    storeFranchise,
    companyName, // TODO: Remove hardcoded companyName conditions
  } = useSelector((state: StateType) => state.storeReducer)
  const { supplierId, subFamilyName, familyName, filteredReferences } =
    useSelector((state: StateType) => state.userInterfaceReducer.inventoryPage)
  const { online, dataSynchronizationStatus } = useSelector(
    (state: StateType) => state.connectionReducer,
  )
  const weekday = new Intl.DateTimeFormat("fr-FR", { weekday: "long" }).format(
    new Date(),
  )

  const [isMercurialeSelectorOpen, setIsMercurialeSelectorOpen] =
    useState(false)

  const suppliers = useMemo(() => {
    if (storeSuppliers === null) return []
    const deduplicateSuppliers = removeDuplicatesValues(storeSuppliers, "id")
    const mercurialeInfos = mercurialAndStoreInventories.filter(
      (mercurialInfo) =>
        filteredReferences === undefined ||
        filteredReferences.length === 0 ||
        filteredReferences.includes(mercurialInfo.mercuriale_id ?? ""),
    )

    const existingInInventoriesSuppliers = deduplicateSuppliers.filter(
      (supplier) =>
        mercurialeInfos.some((item) => item.supplier_id === supplier.id),
    )

    return existingInInventoriesSuppliers.map((supplier) => {
      const minimalOrderValue =
        storeStoreSuppliers?.find(
          (storeStoreSupplier) =>
            storeStoreSupplier.supplier_id === supplier.id &&
            storeStoreSupplier.order_day === weekday,
        )?.minimal_order_value ?? undefined

      return {
        label: supplier.supplier_name,
        value: supplier.id,
        extra: (
          <OrderPrice
            storeCurrency={storeCurrency}
            storeSupplierId={supplier.id}
            minimalOrderValue={minimalOrderValue}
          />
        ),
      }
    })
  }, [
    filteredReferences,
    mercurialAndStoreInventories,
    storeCurrency,
    storeStoreSuppliers,
    storeSuppliers,
    weekday,
  ])

  const categories = useMemo(() => {
    return (
      mercurialAndStoreInventories?.reduce<Record<string, string[]>>(
        (categories, mercurialInfo) => {
          if (
            filteredReferences !== undefined &&
            filteredReferences.length > 0 &&
            !filteredReferences.includes(mercurialInfo.mercuriale_id ?? "")
          ) {
            return categories
          }
          const familyName =
            mercurialInfo?.family_name?.toLowerCase() ?? "autres"
          const subFamilyName = mercurialInfo?.sub_family_name?.toLowerCase()
          if (categories[familyName] === undefined) {
            categories[familyName] =
              typeof subFamilyName === "string" ? [subFamilyName] : []
            return categories
          }
          if (
            typeof subFamilyName === "string" &&
            !categories[familyName].includes(subFamilyName)
          ) {
            categories[familyName] = [...categories[familyName], subFamilyName]
          }
          return categories
        },
        {},
      ) ?? {}
    )
  }, [filteredReferences, mercurialAndStoreInventories])

  useEffect(() => {
    if (
      suppliers.length === 0 ||
      supplierId === undefined ||
      suppliers.some((supplier) => supplier.value === supplierId)
    )
      return
    dispatch({ type: "setFilters", payload: { page, supplierId: undefined } })
  }, [supplierId, suppliers, dispatch, page])

  useEffect(() => {
    if (
      Object.keys(categories).length === 0 ||
      familyName === undefined ||
      Object.keys(categories).includes(familyName)
    )
      return
    dispatch({ type: "setFilters", payload: { page, familyName: undefined } })
  }, [familyName, categories, dispatch, page])

  useEffect(() => {
    if (
      familyName === undefined ||
      subFamilyName === undefined ||
      categories[familyName] === undefined ||
      Object.values(categories[familyName]) === undefined ||
      Object.values(categories[familyName]).length === 0 ||
      Object.values(categories[familyName]).includes(subFamilyName)
    ) {
      return
    }
    dispatch({
      type: "setFilters",
      payload: { page, subFamilyName: undefined },
    })
  }, [familyName, categories, dispatch, subFamilyName, page])

  useEffect(() => {
    if (Object.keys(categories).length !== 1 || familyName !== undefined) return
    dispatch({
      type: "setFilters",
      payload: { page, familyName: Object.keys(categories)[0] },
    })
  }, [familyName, categories, dispatch, page])

  const sortedCategories = useMemo(() => {
    const categoriesKeys = Object.keys(categories)
    const categoriesOrders =
      storeSettings?.categories_orders
        ?.map((category) => category.name)
        .filter(
          (category): category is string =>
            category !== undefined &&
            category !== null &&
            categoriesKeys.includes(category.toLowerCase()),
        ) ?? []
    return removeDuplicates([...categoriesOrders, ...categoriesKeys]).map(
      (category) => ({ value: category.toLowerCase() }),
    )
  }, [categories, storeSettings?.categories_orders])

  const sortedSubCategories = useMemo(() => {
    const subCategories = categories?.[familyName ?? ""] ?? []
    const orderedSubCategories =
      storeSettings?.categories_orders
        ?.find((category) => category.name.toLowerCase() === familyName)
        ?.children.filter((subCategory) =>
          subCategories.includes(subCategory.toLowerCase()),
        ) ?? []
    return removeDuplicates([...orderedSubCategories, ...subCategories]).map(
      (subCategory) => ({ value: subCategory.toLowerCase() }),
    )
  }, [categories, familyName, storeSettings?.categories_orders])

  const mercurialeDate = mercurialAndStoreInventories[0]?.date_integration
  const isValidDate = !isNaN(new Date(mercurialeDate ?? "").getTime())

  return (
    <div className="sticky top-0 z-10 bg-white">
      <div className="flex flex-shrink-0 justify-between">
        <Link
          to="/account/calendar"
          onClick={(e) => {
            if (!online || isLoading) {
              e.preventDefault()
            }
            if (
              dataSynchronizationStatus === DataSynchronizationStatus.FAILURE
            ) {
              e.preventDefault()
              setSaveDataModalState({ isOpen: true, backToHome: true })
            }
          }}
          className="border-r border-gray-200 px-2 lg:px-3 text-gray-500 flex items-center justify-center"
        >
          {isLoading ? (
            <Spinner invertColors className="w-5 lg:w-6" />
          ) : (
            <MinimalLogo
              companyId={companyId}
              testMode={enable}
              franchise={storeFranchise}
            />
          )}
        </Link>
        <div className="hidden md:flex px-4 text-xs lg:text-sm text-zinc-800 border-r border-gray-200 gap-4 relative">
          <div className="flex flex-col items-center justify-center ">
            <p className="whitespace-nowrap font-bold">Mercuriale du</p>
            {isValidDate ? (
              <p>
                {new Intl.DateTimeFormat("fr-FR", {
                  weekday: "long", // This will display the full name of the day
                  day: "2-digit",
                }).format(new Date(mercurialeDate ?? ""))}
              </p>
            ) : (
              <div className="my-1 w-14 h-2 bg-gray-300 rounded-full animate-pulse" />
            )}
          </div>
          {dimMercuriales !== undefined && dimMercuriales.length > 1 && (
            <>
              <button
                onClick={() => setIsMercurialeSelectorOpen((value) => !value)}
              >
                <ChevronDownIcon
                  className={`w-5 h-5 transition-all ${isMercurialeSelectorOpen ? "rotate-180" : "rotate-0"}`}
                />
              </button>
              {isMercurialeSelectorOpen && (
                <div className="absolute left-0 top-10 lg:top-14 z-10 bg-white p-2 rounded shadow text-base w-full border border-gray-200">
                  {dimMercuriales.map((dimMercuriale, index) => (
                    <div key={index} className="flex items-center gap-4">
                      <input
                        onChange={() => {
                          dispatch({
                            type: "resetPageFilters",
                            payload: { page },
                          })
                          dispatch({
                            type: "setSelectedDimMercurialeId",
                            payload: dimMercuriale.dimMercurialeId,
                          })
                          setIsMercurialeSelectorOpen(false)
                        }}
                        type="radio"
                        id={dimMercuriale.dimMercurialeId}
                        name={dimMercuriale.dimMercurialeId}
                        value={dimMercuriale.dimMercurialeId}
                        checked={
                          selectedDimMercurialeId ===
                          dimMercuriale.dimMercurialeId
                        }
                        disabled={
                          dimMercuriale.dimMercurialeId ===
                          selectedDimMercurialeId
                        }
                        className="checked:bg-green-400 hover:checked:bg-green-400 cursor-pointer"
                      />
                      <label
                        htmlFor={dimMercuriale.dimMercurialeId}
                        className="cursor-pointer"
                      >
                        {dimMercuriale.orderPickupTime
                          .slice(0, -3)
                          .replace(":", "h")}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex-1 h-8 lg:h-11" />
        <div className="w-full overflow-y-hidden">
          {storeSettings?.show_suppliers === true && (
            <FLBar
              page={page}
              field="supplierId"
              categories={suppliers}
              activeCategory={supplierId}
              companyName={companyName}
            />
          )}
          {storeSettings?.show_categories === true &&
            storeSettings?.show_suppliers !== true &&
            Object.keys(categories).length > 1 && (
              <FLBar
                page={page}
                field="familyName"
                categories={sortedCategories}
                activeCategory={familyName}
                companyName={companyName}
              />
            )}
          {storeSettings?.show_categories === true &&
            storeSettings?.show_suppliers !== true &&
            Object.keys(categories).length === 1 && (
              <FLBar
                page={page}
                field="subFamilyName"
                categories={sortedSubCategories}
                parentCategory={familyName}
                activeCategory={subFamilyName}
                singleBar
                companyName={companyName}
              />
            )}
        </div>
        {dataSynchronizationStatus === DataSynchronizationStatus.FAILURE && (
          <button
            className="hidden md:flex mx-2 items-center"
            onClick={() =>
              setSaveDataModalState({ isOpen: true, backToHome: false })
            }
          >
            <ExclamationTriangleIcon
              className={`w-5 h-5 lg:w-6 lg:h-6 text-orange-400`}
            />
          </button>
        )}
        <div className="hidden md:flex mx-2 items-center">
          <WifiIcon
            className={`w-5 h-5 lg:w-6 lg:h-6 ${online ? "text-green-500" : "text-red-500"}`}
          />
        </div>
      </div>
      {storeSettings?.show_categories === true &&
        storeSettings?.show_suppliers === true && (
          <div className="border-t border-gray-200">
            <FLBar
              page={page}
              field="familyName"
              className="lg:pl-12"
              categories={sortedCategories}
              activeCategory={familyName}
              companyName={companyName}
            />
          </div>
        )}
      {storeSettings?.show_categories === true &&
        Object.keys(categories).length > 1 &&
        familyName !== undefined &&
        (categories?.[familyName] ?? [])?.length > 1 && (
          <div className="border-t border-gray-200">
            <FLBar
              page={page}
              field="subFamilyName"
              className="lg:pl-12"
              categories={sortedSubCategories}
              parentCategory={familyName}
              activeCategory={subFamilyName}
              companyName={companyName}
            />
          </div>
        )}
      <div
        className={`bg-gray-40 flex justify-center border-t ${enable ? "border-yellow-500" : "border-gray-200"}`}
      >
        {enable && (
          <p
            className={twJoin(
              "w-fit bg-yellow-500 text-yellow-900 rounded-b-md px-2 text-xs leading-4 pb-1",
            )}
          >
            Mode {isReadOnly ? "visualisation" : "test"} - Les modifications ne
            sont pas sauvegardées
          </p>
        )}
      </div>
    </div>
  )
}

export default InventoryNav
