import { useNavigate } from "react-router-dom"
import { Button, ButtonProps } from "../../ui/Button"
import { NavLink } from "../../ui/NavLinkButton"
import { ArrowRightIcon } from "../../ui/icons/ArrowRightIcon"
import {
  ArrowsRightLeftIcon,
  DocumentIcon,
  HandRaisedIcon,
  NewspaperIcon,
} from "@heroicons/react/24/outline"
import { QueueListIcon } from "@heroicons/react/24/solid"
import { ReactNode, useMemo } from "react"

interface NavigationButtonsProps {
  companyName: string | undefined | null
}

type Link =
  | {
      type: "link"
      name: string
      url: string
      icon: ReactNode
      className?: string
    }
  | {
      type: "button"
      name: string
      onClick: () => void
      icon: ReactNode
      color?: ButtonProps["color"]
      size?: ButtonProps["size"]
    }

const NavigationButtons = ({ companyName }: NavigationButtonsProps) => {
  const navigate = useNavigate()

  const links = useMemo<Link[]>(() => {
    switch (companyName?.toLowerCase()) {
      case "marceletfils":
        return [
          {
            type: "link",
            name: "Actus FL",
            url: "http://192.168.103.50/index.php?uc=Accueil&action=Afficher",
            icon: <NewspaperIcon className="w-6 h-6 pr-2" />,
            className: "hidden md:flex",
          },
          {
            type: "link",
            name: "Avoirs",
            url: "http://192.168.103.50/index.php?uc=Avoir&action=Afficher",
            icon: <HandRaisedIcon className="w-6 h-6 pr-2" />,
            className: "hidden md:flex",
          },
          {
            type: "link",
            name: "Docs",
            url: "http://192.168.103.50/index.php?uc=Documents&action=Afficher",
            icon: <DocumentIcon className="w-6 h-6 pr-2" />,
            className: "hidden md:flex",
          },
          {
            type: "button",
            name: "Ordre du rayon",
            onClick: () => navigate("/account/categories"),
            icon: <QueueListIcon className="w-5 h-5" />,
            color: "ghost",
            size: "adaptive-custom",
          },
          {
            type: "button",
            name: "Passer commande",
            onClick: () => navigate("/inventory"),
            icon: <ArrowRightIcon className="w-5 h-5" />,
            size: "adaptive-custom",
          },
        ]
      case "biomonde":
        return [
          {
            type: "button",
            name: "Référentiel produit",
            onClick: () => navigate("/matching"),
            icon: <ArrowsRightLeftIcon className="w-5 h-5" />,
            color: "ghost",
          },
          {
            type: "button",
            name: "Ordre du rayon",
            onClick: () => navigate("/account/categories"),
            icon: <QueueListIcon className="w-5 h-5" />,
            color: "ghost",
          },
          {
            type: "button",
            name: "Passer commande",
            onClick: () => navigate("/inventory"),
            icon: <ArrowRightIcon className="w-5 h-5" />,
          },
        ]
      default:
        return [
          {
            type: "button",
            name: "Modifier l'ordre du rayon",
            onClick: () => navigate("/account/categories"),
            icon: <QueueListIcon className="w-5 h-5" />,
            color: "ghost",
          },
          {
            type: "button",
            name: "Passer commande",
            onClick: () => navigate("/inventory"),
            icon: <ArrowRightIcon className="w-5 h-5" />,
          },
        ]
    }
  }, [companyName, navigate])

  return (
    <div className="flex flex-col md:flex-row gap-2 items-center w-full">
      {links.map((link, index) => {
        if (link.type === "link") {
          return (
            <NavLink
              key={index}
              url={link.url}
              label={link.name}
              color="green2"
              size="sm"
              leftIcon={link.icon}
              className={link.className}
            />
          )
        }

        return (
          <Button
            key={index}
            size={link.size ?? "md"}
            className="w-full text-nowrap"
            leftIcon={link.icon}
            onClick={link.onClick}
            color={link.color}
          >
            {link.name}
          </Button>
        )
      })}
    </div>
  )
}

export default NavigationButtons
