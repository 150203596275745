import { useMemo } from "react"
import { useSelector } from "react-redux"
import { StateType } from "../types"

interface usePermissionsResult {
  isIdaAdmin: boolean
  isAuthAdmin: boolean
  isReadOnly: boolean
  hasPermissions: (permissions: string[]) => boolean
}

export function usePermissions(): usePermissionsResult {
  const tokenData = useSelector(
    (state: StateType) => state.authReducer.tokenData,
  )

  const isIdaAdmin = useMemo(() => {
    return tokenData?.permissions.includes("ida:admin") ?? false
  }, [tokenData])

  const isAuthAdmin = useMemo(() => {
    return tokenData?.permissions.includes("auth:admin") ?? false
  }, [tokenData?.permissions])

  const isReadOnly = useMemo(() => {
    return tokenData?.permissions.includes("arolya:inventory:write") !== true
  }, [tokenData?.permissions])

  function hasPermissions(permissions: string[]) {
    return permissions.every((permission) =>
      tokenData?.permissions.includes(permission),
    )
  }

  return {
    isIdaAdmin,
    isAuthAdmin,
    hasPermissions,
    isReadOnly,
  }
}
