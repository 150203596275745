import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline"
import { RecapSortOption } from "../../reducers/userInterfaceReducer"

interface SortIconProps {
  column: RecapSortOption
  sortedColumn: RecapSortOption | null
  sortOrder: "asc" | "desc"
}

const SortIcon = ({ column, sortedColumn, sortOrder }: SortIconProps) => {
  if (column !== sortedColumn) {
    return <ArrowDownIcon className="w-4 h-4 text-gray-500" />
  }
  if (sortOrder === "asc") {
    return <ArrowDownIcon className="w-4 h-4 text-green-500" />
  }
  return <ArrowUpIcon className="w-4 h-4 text-green-500" />
}

export default SortIcon
