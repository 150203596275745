import { AlertColor } from "@mui/material"
import { ReactNode } from "react"

export interface ErrorsReducerState {
  snackbar:
    | {
        type: AlertColor
        message: string
        icon?: ReactNode
      }
    | undefined
}

export const defaultErrorsReducerState: ErrorsReducerState = {
  snackbar: undefined,
}

interface SetSnackbarAction {
  type: "setSnackbar"
  payload: ErrorsReducerState["snackbar"]
}

export type ErrorsReducerAction = SetSnackbarAction

const errorsReducer = (
  state: ErrorsReducerState = defaultErrorsReducerState,
  action: ErrorsReducerAction,
): ErrorsReducerState => {
  switch (action.type) {
    case "setSnackbar":
      return {
        ...state,
        snackbar: action.payload,
      }
    default:
      return state
  }
}

export default errorsReducer
