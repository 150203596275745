import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  useBatchUpdateMutation,
  useLogsModificationUpdateMutation,
} from "../utils/__generated__/graphql"
import { type DispatchActionType, type StateType } from "../types"
import { type AllMercurialInfo } from "../reducers/mercurialReducer"
import { InventoryPageTemplate } from "../components/inventory/InventoryPageTemplate"
import { handleKeyboard } from "../utils/handleKeyboard"
import { Dialog, Transition } from "@headlessui/react"
import { AlertTriangleIcon } from "../assets/AlertTriangle"
import { Button } from "../ui/Button"
import { SortOption } from "../reducers/userInterfaceReducer"
import {
  alphabeticalSort,
  categoriesSort,
  customBooleanComparator,
  customNullLastComparator,
  numericalSort,
} from "../utils/sort"
import {
  computeQuantityActual,
  computeStockQuantity,
} from "../utils/computeInventoriesValues"
import { InventoryRow } from "../components/inventory/InventoryRow"
import {
  filteredMercurialeReducerSelector,
  selectedRankingsSelector,
} from "../selectors/mercurialeSelectors"
import { MultipleMercurialesModal } from "../components/inventory/MultipleMercurialesModal"
import { removeDuplicatesValues } from "../utils/removeDuplicates"
import { useOutletContext } from "react-router-dom"
import { InventoryContext } from "../components/inventory/InventoryRoot"
import { useSearchBar } from "../hooks/useSearchBar"

// Sort options that should not keep the same order when updating data
const bypassOldSortOptions = [SortOption.SortIndex]

function InventoryPage(): JSX.Element {
  const dispatch = useDispatch<DispatchActionType>()
  const [{ isLoading }, getLatestInfos] = useOutletContext<InventoryContext>()

  const {
    storeId,
    storeSettings,
    storeSuppliers,
    storeCurrency,
    companyId,
    companyName,
  } = useSelector((state: StateType) => state.storeReducer)
  const {
    mercurialAndStoreInventories,
    updatedReferences,
    selectedDimMercurialeId,
  } = useSelector(filteredMercurialeReducerSelector)
  const rankings = useSelector(selectedRankingsSelector)
  const {
    searchTerm,
    familyName,
    subFamilyName,
    supplierId,
    filteredReferences,
    sortOption,
    displayShelfFloorSize,
  } = useSelector(
    (state: StateType) => state.userInterfaceReducer.inventoryPage,
  )
  const selectedInventory = useSelector(
    (state: StateType) => state.userInterfaceReducer.selectedInventory,
  )
  const isTestMode = useSelector(
    (state: StateType) => state.trainingModeReducer.enable,
  )
  const online = useSelector(
    (state: StateType) => state.connectionReducer.online,
  )
  // Keep sort list and option to keep order when updating data
  const oldState = useRef<{
    sortList?: string[]
    sortOption?: SortOption
    selectedDimMercurialeId?: string
  } | null>(null)
  const dimMercuriales = useSelector(
    (state: StateType) => state.mercurialReducer.dimMercuriales,
  )
  const dimOrderRequestId = dimMercuriales?.find(
    (dimMercuriale) =>
      dimMercuriale.dimMercurialeId ===
      mercurialAndStoreInventories[0]?.dim_mercuriale_id,
  )?.dimOrderRequestId

  const [isReloadModalOpen, setIsReloadModal] = useState(false)
  const [isMercurialeModalOpen, setIsMercurialeModalOpen] = useState(false)

  const [batchUpdateMutation] = useBatchUpdateMutation()
  const [logsModificationUpdate] = useLogsModificationUpdateMutation()

  useEffect(() => {
    if ((dimMercuriales ?? [])?.length < 2) {
      setIsMercurialeModalOpen(false)
      return
    }
    setIsMercurialeModalOpen(true)
  }, [dimMercuriales])

  const interval = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (isReloadModalOpen) return

    interval.current = setInterval(() => {
      setIsReloadModal(true)
      clearInterval(interval.current)
    }, 3600000) // 1 hour

    return () => {
      clearInterval(interval.current)
    }
  }, [isReloadModalOpen])

  const mercurialeInfos = useMemo<Partial<AllMercurialInfo>[]>(() => {
    return mercurialAndStoreInventories.map((mercurialeInfo) => {
      const updatedReference =
        updatedReferences[mercurialeInfo.mercuriale_id ?? ""]
      return {
        ...mercurialeInfo,
        back_inventory_qty:
          updatedReference?.backInventoryQuantity ??
          mercurialeInfo.back_inventory_qty,
        floor_inventory_qty:
          updatedReference?.floorInventoryQuantity ??
          mercurialeInfo.floor_inventory_qty,
        quantity_actual:
          updatedReference?.orderInventoryQuantity ??
          mercurialeInfo.quantity_actual,
        shelf_floor_size:
          updatedReference?.shelfFloorSize ?? mercurialeInfo.shelf_floor_size,
        stock_too_high_flag:
          updatedReference?.stock_too_high_flag ??
          mercurialeInfo.stock_too_high_flag,
        stock_too_low_flag:
          updatedReference?.stock_too_low_flag ??
          mercurialeInfo.stock_too_low_flag,
        stock_to_verify_flag:
          updatedReference?.stock_to_verify_flag ??
          mercurialeInfo.stock_to_verify_flag,
      }
    })
  }, [mercurialAndStoreInventories, updatedReferences])

  const bestSellers = useMemo(() => {
    return [...mercurialAndStoreInventories]
      .sort((a, b) => {
        if (a.last_weeks_sales === b.last_weeks_sales) return 0
        return (a.last_weeks_sales ?? 0) > (b.last_weeks_sales ?? 0) ? -1 : 1
      })
      .slice(0, 19)
  }, [mercurialAndStoreInventories])

  // Sort logic
  const sortedMercurialeInfos = useMemo<Partial<AllMercurialInfo>[]>(() => {
    const _mercurialesInfos = [...mercurialeInfos]

    // If sort option is same than older, keep the same list order
    if (
      !bypassOldSortOptions.includes(sortOption) &&
      oldState.current?.sortOption === sortOption &&
      oldState.current.sortList !== undefined &&
      oldState.current.selectedDimMercurialeId === selectedDimMercurialeId
    ) {
      return oldState.current.sortList
        .map((mercurialeId) => {
          const mercurialeInfo = _mercurialesInfos.find(
            (_filteredMercurialeInfo) =>
              _filteredMercurialeInfo.mercuriale_id === mercurialeId,
          )
          return mercurialeInfo
        })
        .filter(
          (mercurialeInfo): mercurialeInfo is Partial<AllMercurialInfo> =>
            mercurialeInfo !== undefined,
        )
    }
    const _sortedMercurialeInfos = _mercurialesInfos.sort((a, b) => {
      switch (sortOption) {
        case SortOption.Alphabetical:
          return alphabeticalSort(
            a.mercuriale_name ?? "",
            b.mercuriale_name ?? "",
          )
        case SortOption.BestSeller:
          return numericalSort(
            a.last_weeks_sales ?? 0,
            b.last_weeks_sales ?? 0,
            "desc",
          )
        case SortOption.Breakage:
          return numericalSort(
            a.breakage_percentage ?? 0,
            b.breakage_percentage ?? 0,
            "desc",
          )
        case SortOption.Promotion:
          return customBooleanComparator(a.promotion, b.promotion)
        case SortOption.QuantityActual:
          return numericalSort(
            computeQuantityActual(a, storeSettings?.use_kg_pce) ?? 0,
            computeQuantityActual(b, storeSettings?.use_kg_pce) ?? 0,
            "desc",
          )
        case SortOption.StockQuantity:
          return numericalSort(
            computeStockQuantity(
              a,
              storeSettings?.use_kg_pce,
              storeSettings?.separate_floor_inventory,
            ) ?? 0,
            computeStockQuantity(
              b,
              storeSettings?.use_kg_pce,
              storeSettings?.separate_floor_inventory,
            ) ?? 0,
            "desc",
          )
        case SortOption.SortIndex: {
          const sortIndexA =
            rankings?.find(
              (ranking) => ranking.mercuriale_id === a.mercuriale_id,
            )?.ranking_filter ?? undefined
          const sortIndexB =
            rankings?.find(
              (ranking) => ranking.mercuriale_id === b.mercuriale_id,
            )?.ranking_filter ?? undefined
          return customNullLastComparator(sortIndexA, sortIndexB)
        }
        case SortOption.Typology:
          return alphabeticalSort(a.typology ?? "", b.typology ?? "")
        case SortOption.Categories:
          return categoriesSort(a, b, storeSettings?.categories_orders)
        default:
          return numericalSort(
            a.last_weeks_sales ?? 0,
            b.last_weeks_sales ?? 0,
            "desc",
          )
      }
    })
    // Save sort option and sort order to refs
    oldState.current = {
      sortOption,
      sortList: _sortedMercurialeInfos.map(
        (_sortedMercurialeInfo) => _sortedMercurialeInfo.mercuriale_id ?? "",
      ),
      selectedDimMercurialeId: selectedDimMercurialeId,
    }
    return _sortedMercurialeInfos
  }, [
    mercurialeInfos,
    rankings,
    selectedDimMercurialeId,
    sortOption,
    storeSettings?.categories_orders,
    storeSettings?.separate_floor_inventory,
    storeSettings?.use_kg_pce,
  ])

  const searchedMercurialeInfos = useSearchBar(
    sortedMercurialeInfos,
    searchTerm,
  )

  // Other filters logic (separated from search filter to retrieve number of filtered items)
  const filteredMercurialeInfos = useMemo<Partial<AllMercurialInfo>[]>(() => {
    return searchedMercurialeInfos.filter(
      (mercurialeInfo) =>
        // Filter bar to filter by category
        (familyName === undefined ||
          familyName === "autres" ||
          mercurialeInfo.family_name?.toLowerCase() === familyName) &&
        // Sub filter bar to filter by subcategory
        (subFamilyName === undefined ||
          mercurialeInfo.sub_family_name?.toLowerCase() === subFamilyName) &&
        // Filter bar to filter by supplier
        (supplierId === undefined ||
          (mercurialeInfo.supplier_id === supplierId &&
            companyName === "biomonde")) &&
        // References to verified button (References to verify)
        ((filteredReferences ?? []).length === 0 ||
          filteredReferences?.includes(mercurialeInfo.mercuriale_id ?? "")) &&
        // Typology setting
        (storeSettings?.typologies === undefined ||
          storeSettings?.typologies === null ||
          storeSettings?.typologies.includes(mercurialeInfo.typology ?? "")),
    )
  }, [
    companyName,
    familyName,
    filteredReferences,
    searchedMercurialeInfos,
    storeSettings?.typologies,
    subFamilyName,
    supplierId,
  ])

  // Other filters logic (separated from search filter to retrieve number of filtered items)
  const deduplicateFilteredMercurialeInfos = useMemo<
    Partial<AllMercurialInfo>[]
  >(() => {
    return removeDuplicatesValues(filteredMercurialeInfos, "sale_id")
  }, [filteredMercurialeInfos])

  const unfilteredAmount = useMemo(
    () => mercurialeInfos.length,
    [mercurialeInfos.length],
  )
  const searchedAmount = useMemo(
    () => searchedMercurialeInfos.length,
    [searchedMercurialeInfos.length],
  )
  const filteredAmount = useMemo(
    () => filteredMercurialeInfos.length,
    [filteredMercurialeInfos.length],
  )

  const updateInventory = useCallback(
    async (
      value: string,
      _selectedInventory?: StateType["userInterfaceReducer"]["selectedInventory"],
    ): Promise<void> => {
      await handleKeyboard({
        value,
        selectedInventory: _selectedInventory ?? selectedInventory,
        mercurialeInfos: mercurialAndStoreInventories,
        updatedReferences,
        storeSettings,
        dispatch,
        batchUpdateMutation,
        online,
        isTestMode,
        storeId,
        dimOrderRequestId,
        logsModificationUpdate,
      })
    },
    [
      batchUpdateMutation,
      dimOrderRequestId,
      dispatch,
      isTestMode,
      mercurialAndStoreInventories,
      online,
      selectedInventory,
      storeId,
      storeSettings,
      updatedReferences,
      logsModificationUpdate,
    ],
  )

  return (
    <>
      <Transition appear show={isReloadModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsReloadModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all flex flex-col gap-4">
                  <div className="flex justify-center items-center">
                    <AlertTriangleIcon />
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    Voulez-vous récupérer les dernières données de la mercuriale
                    ?
                  </Dialog.Title>
                  <p className="text-center text-gray-500 text-sm">
                    La mercuriale n&apos;a pas été mise à jour depuis 1 heure.
                  </p>
                  <div className="w-full flex items-center gap-3 justify-center">
                    <Button
                      onClick={() => setIsReloadModal(false)}
                      className="w-full h-[44px] text-[16px]"
                      color="ghost"
                    >
                      Annuler
                    </Button>
                    <Button
                      color="green"
                      onClick={() => {
                        oldState.current = null
                        void getLatestInfos()
                        setIsReloadModal(false)
                      }}
                      className="w-full h-[44px] text-[16px]"
                    >
                      Mettre à jour
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {!isLoading && (
        <MultipleMercurialesModal
          isOpen={isMercurialeModalOpen}
          onClose={() => setIsMercurialeModalOpen(false)}
          onSelect={(dimMercurialeId) => {
            oldState.current = null
            dispatch({
              type: "setSelectedDimMercurialeId",
              payload: dimMercurialeId,
            })
            setIsMercurialeModalOpen(false)
          }}
          dimMercuriales={dimMercuriales}
        />
      )}
      <InventoryPageTemplate
        page="inventoryPage"
        loading={isLoading}
        rawData={mercurialeInfos}
        dataLength={deduplicateFilteredMercurialeInfos.length}
        searchedAmount={searchedAmount}
        filteredAmount={filteredAmount}
        unfilteredAmount={unfilteredAmount}
        updateInventory={updateInventory}
        rowContent={(index) => {
          const row = deduplicateFilteredMercurialeInfos[index]
          const references = filteredMercurialeInfos.filter(
            (mercurialeInfo) => mercurialeInfo.sale_id === row.sale_id,
          )

          return (
            <InventoryRow
              index={index}
              storeId={storeId}
              bestSellers={bestSellers}
              selectedInventory={selectedInventory}
              isOnline={online}
              storeSettings={storeSettings}
              updateInventory={updateInventory}
              displayShelfFloorSize={displayShelfFloorSize}
              storeSuppliers={storeSuppliers}
              storeCurrency={storeCurrency}
              companyId={companyId}
              references={references}
            />
          )
        }}
      />
    </>
  )
}

export default InventoryPage
