import {
  type StoreSettings,
  type StoreSupplier,
  type Supplier,
} from "../utils/__generated__/graphql"

export interface StoreReducerState {
  storeId: string | null
  storeName: string | null
  storeCity: string | null
  storeCountry: string | null
  companyId: string | null
  storeSettings: StoreSettings | null
  storeSuppliers: Supplier[] | null
  storeStoreSuppliers: StoreSupplier[] | null
  storeCurrency: string | null
  storeCode: string | null
  companyName: string | null
  selectedStoreId: string | undefined
  storeFranchise: string | null
}

export const defaultStoreReducerState: StoreReducerState = {
  storeId: null,
  storeName: null,
  storeCity: null,
  storeCountry: null,
  companyId: null,
  storeSettings: null,
  storeSuppliers: null,
  storeStoreSuppliers: null,
  storeCurrency: null,
  storeCode: null,
  companyName: null,
  selectedStoreId: undefined,
  storeFranchise: null,
}

interface SetStoreAction {
  type: "setStore"
  payload: Partial<StoreReducerState>
}

interface SetSeparateFloorInventory {
  type: "setSeparateFloorInventory"
  payload: boolean
}

export type StoreReducerAction = SetStoreAction | SetSeparateFloorInventory

const storeReducer = (
  state: StoreReducerState = defaultStoreReducerState,
  action: StoreReducerAction,
): StoreReducerState => {
  switch (action.type) {
    case "setStore":
      return {
        ...state,
        ...action.payload,
      }
    case "setSeparateFloorInventory":
      return {
        ...state,
        storeSettings: {
          ...state.storeSettings,
          separate_floor_inventory: action.payload,
        },
      }
    default:
      return state
  }
}

export default storeReducer
