import { useDispatch, useSelector } from "react-redux"
import { DispatchActionType, StateType } from "../../types"

const SearchBar = () => {
  const dispatch = useDispatch<DispatchActionType>()
  const searchTerm = useSelector(
    (state: StateType) => state.userInterfaceReducer.recapPage.searchTerm,
  )

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "setSearchBar",
      payload: {
        page: "recapPage",
        searchTerm: e.target.value,
      },
    })
  }

  return (
    <div className="w-full bg-[#8A8A8A] rounded-t">
      <div className="w-full p-3 sm:p-4 lg:p-5">
        <div className="flex flex-col md:flex-row justify-center items-center w-full md:items-center">
          <div className="hidden md:block sm:flex-auto">
            <h1 className="text-xl font-semibold leading-6 text-white">
              Vos produits
            </h1>
          </div>
          <div className="w-full md:w-1/3">
            <input
              type="text"
              onChange={handleSearchChange}
              placeholder="Rechercher un produit"
              className={`w-full block rounded-md bg-[#B8B8B8] px-3 py-2 text-base font-semibold text-white shadow-sm placeholder:text-white placeholder:font-normal border-2 ring-0 focus:ring-0 focus:border-green-500 ${searchTerm !== "" ? "border-green-500" : "border-[#B8B8B8]"}`}
              value={searchTerm}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchBar
