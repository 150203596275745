import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import { type StateType } from "../types"
import MinimalLogo from "../assets/MinimalLogo"
import {
  XMarkIcon,
  Cog6ToothIcon,
  ShieldExclamationIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline"
import { usePermissions } from "../hooks/usePermissions"
import { useAuth0 } from "@auth0/auth0-react"

const fullScreenRoutes = [
  "/account/settings",
  "/account/settings/",
  "/account/admin/",
  "/account/categories",
]

const Header = (): JSX.Element => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const { enable } = useSelector(
    (state: StateType) => state.trainingModeReducer,
  )
  const { companyId, storeFranchise } = useSelector(
    (state: StateType) => state.storeReducer,
  )

  const { user } = useAuth0()
  const location = useLocation()
  const { hasPermissions, isAuthAdmin, isIdaAdmin } = usePermissions()

  // Effect to update window height when the window is resized
  useEffect(() => {
    const handleResize = (): void => {
      setWindowHeight(window.innerHeight)
    }
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <header
      className={`px-2 md:px-4 flex items-center justify-between ${
        windowHeight > 700 ? "py-4" : "py-2"
      }`}
    >
      <div className="flex gap-3 items-center">
        <Link to="/inventory">
          <MinimalLogo
            companyId={companyId}
            testMode={enable}
            franchise={storeFranchise}
            className="w-6 lg:w-8"
          />
        </Link>
        <p className="text-base text-[#323232] font-medium">{user?.email}</p>
      </div>
      <nav className="flex justify-center gap-1 md:gap-4">
        {fullScreenRoutes.some((route) =>
          location?.pathname.includes(route),
        ) ? (
          <Link to="/account/calendar">
            <XMarkIcon className="w-6 h-6" />
          </Link>
        ) : (
          <>
            {isAuthAdmin && (
              <Link to="/users">
                <LockClosedIcon className="w-6 h-6" />
              </Link>
            )}
            {hasPermissions(["arolya:company:admin"]) && (
              <Link
                to={
                  isIdaAdmin
                    ? "/account/admin"
                    : `/account/admin/companies/${companyId}${storeFranchise !== null ? `?franchise=${storeFranchise}` : ""}`
                }
              >
                <ShieldExclamationIcon className="w-6 h-6" />
              </Link>
            )}
            <Link to="/account/settings">
              <Cog6ToothIcon className="w-6 h-6" />
            </Link>
          </>
        )}
      </nav>
    </header>
  )
}

export default Header
