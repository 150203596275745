import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import {
  ChevronDownIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/24/outline"
import {
  ChangeEvent,
  Fragment,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from "react"
import { twJoin, twMerge } from "tailwind-merge"
import { getQuarterValue } from "../utils/getQuarterValue"

export interface SelectorData<T> {
  value: T
  label: ReactNode
  selectedLabel?: ReactNode
}

export type RingColor = "red" | "orange" | "lightOrange" | "yellow"

interface InventoryInputMobileProps<T> {
  value: number
  onChange: (value: string) => void
  onFocus?: () => void
  disabled?: boolean
  unit?: string
  bottomElement?: ReactNode
  selectorData?: SelectorData<T>[]
  selectorValue?: SelectorData<T>["value"]
  onSelectorChange?: (value: SelectorData<T>["value"], index: number) => void
  ringColor?: RingColor
  selected: boolean
}

export function InventoryInputMobile<T>({
  value,
  onChange,
  onFocus,
  disabled = false,
  unit,
  bottomElement,
  selectorData,
  selectorValue,
  onSelectorChange,
  ringColor,
  selected,
}: InventoryInputMobileProps<T>) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [localValue, setLocalValue] = useState<string>()

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.value === "") {
      onChange("0")
      setLocalValue("0")
      return
    }

    const parsedValue = parseFloat(event.target.value)
    if (isNaN(parsedValue)) return

    const stringifiedValue = `${parsedValue}`

    if (event.target.value.endsWith(".") || event.target.value.endsWith(",")) {
      setLocalValue(stringifiedValue + ".")
      onChange(stringifiedValue)
      return
    }

    const integer = stringifiedValue.split(".")[0]

    setLocalValue(integer)
    onChange(stringifiedValue)
  }

  function handleButtonClick(type: "minus" | "plus") {
    const _value = type === "minus" ? value - 1 : value + 1
    onChange(`${_value < 0 ? 0 : _value}`)
  }

  const displayedValue = getQuarterValue(value, "size-4")

  const selectorDataLabel = useMemo(() => {
    if (selectorData === undefined) return

    const selectedData = selectorData.find(
      (data) => data.value === selectorValue,
    )

    return selectedData?.selectedLabel ?? selectedData?.label ?? "-"
  }, [selectorData, selectorValue])

  return (
    <div>
      <div
        className={twJoin(
          "flex items-center bg-gray-100 p-1",
          selectorData !== undefined
            ? "rounded-t-3xl border-b-0"
            : "rounded-full",
          ringColor === "red" && "border-2 border-red-700",
          ringColor === "orange" && "border-2 border-custom-orange",
          ringColor === "lightOrange" && "border-2 border-custom-lightOrange",
          ringColor === "yellow" && "border-2 border-yellow-500",
        )}
      >
        <button
          onClick={() => handleButtonClick("minus")}
          disabled={disabled || value <= 0}
          className="bg-green-20 hover:bg-green-30 shadow text-green-900 rounded-full w-8 h-8 flex justify-center items-center disabled:bg-gray-300 disabled:text-white"
        >
          <MinusIcon className="w-4 h-4" />
        </button>
        <div
          className={twMerge(
            "w-full flex-1 flex items-center",
            disabled ? "cursor-auto text-gray-300" : "cursor-pointer",
            selected && "text-green-500",
          )}
          onClick={() => {
            if (disabled) return
            inputRef.current?.focus()
          }}
        >
          <input
            ref={inputRef}
            type="text"
            inputMode="decimal"
            placeholder={value < 0 ? "-" : ""}
            value={
              localValue ?? (value < 0 ? "-" : (displayedValue.value ?? ""))
            }
            onChange={handleChange}
            min={0}
            disabled={disabled}
            onFocus={() => {
              onFocus?.()
              // This logic aims to use fresh value when user click on the input and user keyboard to update input
              setLocalValue("")
              if (inputRef.current === null) return
              inputRef.current.placeholder = inputRef.current?.value
            }}
            onBlur={() => {
              setLocalValue(undefined)
            }}
            className={twJoin(
              "appearance-none h-8 w-full flex-1 bg-transparent p-0 border-none ring-0 outline-0 focus:outline-none focus:ring-0 caret-transparent text-xs font-bold decoration-2 cursor-pointer disabled:cursor-auto selection:bg-transparent selection:text-inherit text-inherit placeholder:text-inherit",
              displayedValue.decimal === undefined
                ? "text-center"
                : "text-right",
            )}
          />
          {displayedValue.icon !== undefined && (
            <div className="h-8 flex-1 flex items-center">
              {displayedValue.icon}
            </div>
          )}
        </div>
        <button
          onClick={() => handleButtonClick("plus")}
          disabled={disabled}
          className="bg-green-20 hover:bg-green-30 shadow text-green-900 rounded-full w-8 h-8 flex justify-center items-center disabled:bg-gray-300 disabled:text-white"
        >
          <PlusIcon className="w-4 h-4" />
        </button>
      </div>
      <div
        className={twJoin(
          "px-1 pb-1 relative pt-2",
          selectorData !== undefined && "bg-gray-100 rounded-b-3xl",
          selectorData !== undefined &&
            ringColor === "red" &&
            "border-2 border-t-0 border-red-700",
          selectorData !== undefined &&
            ringColor === "orange" &&
            "border-2 border-t-0 border-custom-orange",
          selectorData !== undefined &&
            ringColor === "lightOrange" &&
            "border-2 border-t-0 border-custom-lightOrange",
          selectorData !== undefined &&
            ringColor === "yellow" &&
            "border-2 border-t-0 border-yellow-500",
        )}
      >
        <div className="absolute -top-2 left-0 w-full flex justify-center items-center">
          <p
            className={twJoin(
              "text-xxs font-bold rounded-sm px-2 pb-1",
              (ringColor === undefined || selectorData !== undefined) &&
                "bg-gray-100 text-neutral-500",
              ringColor === "red" &&
                selectorData === undefined &&
                "bg-red-700 text-white",
              ringColor === "orange" &&
                selectorData === undefined &&
                "bg-custom-orange text-white",
              ringColor === "lightOrange" &&
                selectorData === undefined &&
                "bg-custom-lightOrange text-white",
              ringColor === "yellow" &&
                selectorData === undefined &&
                "bg-yellow-500 text-white",
            )}
          >
            {unit}
          </p>
        </div>
        {selectorData !== undefined && (
          <Menu>
            <MenuButton className="text-xs w-full flex justify-center items-center font-bold gap-1">
              {({ active }) => (
                <>
                  {selectorDataLabel}
                  <ChevronDownIcon
                    className={twJoin(
                      "size-4 transition-transform",
                      active ? "rotate-180" : "rotate-0",
                    )}
                  />
                </>
              )}
            </MenuButton>

            <MenuItems
              transition
              anchor="bottom end"
              className="bg-white rounded shadow p-2 w-fit text-neutral-500 text-xs font-bold"
            >
              {selectorData.map((data, i) => (
                <Fragment key={i}>
                  <MenuItem data-active={false}>
                    <button
                      className="w-full data-[focus]:bg-neutral-500 data-[focus]:!text-white rounded py-1 px-2"
                      onClick={() => onSelectorChange?.(data.value, i)}
                    >
                      {data.label}
                    </button>
                  </MenuItem>
                  {i < selectorData.length - 1 && <hr />}
                </Fragment>
              ))}
            </MenuItems>
          </Menu>
        )}
      </div>
      {bottomElement}
    </div>
  )
}
