// transition.js

export const pageTransition = {
  initial: {
    opacity: 1,
    x: "-100%",
  },
  initialReverse: {
    opacity: 1,
    x: "100%",
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 1,
    x: "100%",
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
  exitReverse: {
    opacity: 0,
    x: "100%",
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
}
export const pageTransitionCalendar = {
  initial: {
    opacity: 1,
    x: "100%",
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 1,
    x: "100%",
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
  exitReverse: {
    opacity: 0,
    x: "100%",
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
}
export const dropDownVariant = {
  initial: {
    width: "0px",
    zIndex: 1,
    opacity: 0,
    height: 178,
    transition: { duration: 0.3 },
  },
  visible: {
    width: "160px",
    opacity: 1,
    zIndex: 20,
    height: 178,
    transition: { duration: 0.3 },
  },
  exit: {
    opacity: 0,
    zIndex: 1,
    width: "0px",
    height: 0,
    transition: { duration: 0.3, delay: 0.2 },
  },
}
export const listVariant = {
  initial: {
    x: -25,
    zIndex: 1,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    zIndex: 20,
    transition: {
      delay: 0.3,
      y: { stiffness: 100, velocity: -100 },
    },
  },
  exit: {
    x: 0,
    opacity: 0,
    zIndex: 1,
    transition: {
      duration: 0.2,
    },
  },
}
export const selectedSort = {
  initial: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.3,
      duration: 0.2,
    },
  },
}
export const errorQuantity = {
  initial: {
    opacity: 0,
    left: "-90px",
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.3,
      duration: 0.2,
    },
  },
}
