import { TrashIcon } from "@heroicons/react/24/solid"
import { Label } from "../../ui/Label"

const breakages: Record<number, "green" | "orange" | "red"> = {
  1: "green",
  2: "orange",
  3: "red",
}

const getShrinkageResult = (shrinkage: number): 1 | 2 | 3 => {
  if (shrinkage < 5) {
    return 1
  } else if (shrinkage >= 5 && shrinkage < 10) {
    return 2
  } else {
    return 3
  }
}

const BreakageLabel = ({
  shrinkage,
  expanded = false,
}: {
  shrinkage: number | null
  expanded?: boolean
}): JSX.Element | null => {
  if (shrinkage === null) return null

  const shrinkableResult = getShrinkageResult(shrinkage)
  const color = breakages[shrinkableResult]

  return (
    <Label color={color} leftIcon={<TrashIcon className="w-4 h-4" />}>
      <span className={`${expanded ? "inline" : "hidden"} md:inline`}>
        Démarque{" "}
      </span>
      {shrinkage}%
    </Label>
  )
}

export default BreakageLabel
