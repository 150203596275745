import { useMemo } from "react"
import { useSelector } from "react-redux"
import { StateType } from "../types"
import { getTotalStock } from "../utils/getStockQuantity"
import { filteredMercurialeReducerSelector } from "../selectors/mercurialeSelectors"
import { AllMercurialInfo } from "../reducers/mercurialReducer"
import { GetOrderItem } from "../utils/__generated__/graphql"

interface PotentialRevenue {
  totalOrders?: number
  potentialCommandRevenue?: number
  potentialOrderRevenue?: number
  valuedStock?: number
  orderValuedStock?: number
  orderPrice?: number
}

interface UsePotentialRevenueProps {
  suppliersIds?: string[]
  references?: (AllMercurialInfo | GetOrderItem)[]
}

export function usePotentialRevenue({
  suppliersIds,
  references,
}: UsePotentialRevenueProps = {}): PotentialRevenue {
  const { storeSettings } = useSelector(
    (state: StateType) => state.storeReducer,
  )
  const { mercurialAndStoreInventories, updatedReferences } = useSelector(
    filteredMercurialeReducerSelector,
  )

  const potentialRevenue = useMemo(() => {
    const valuedStockSaleIds: string[] = []
    const orderValuedStockSaleIds: string[] = []
    return (references ?? mercurialAndStoreInventories)
      ?.filter(
        (mercurialAndStoreInventory) =>
          suppliersIds === undefined ||
          suppliersIds.includes(mercurialAndStoreInventory.supplier_id ?? ""),
      )
      ?.reduce<PotentialRevenue>((acc, curr) => {
        const updatedReference =
          "sale_id" in curr
            ? updatedReferences[curr.mercuriale_id ?? ""]
            : undefined

        const salePrice = curr?.pv ?? 0
        const orderPrice = curr?.pa ?? 0
        let valuedStock = acc.valuedStock ?? 0
        let orderValuedStock = acc.orderValuedStock

        // Deduplicated sale id for valued stock
        if (
          "sale_id" in curr &&
          !valuedStockSaleIds.includes(curr.sale_id ?? "")
        ) {
          const stockQuantity = getTotalStock({
            backQuantity:
              updatedReference?.backInventoryQuantity ??
              curr?.back_inventory_qty ??
              0,
            floorQuantity:
              updatedReference?.floorInventoryQuantity ??
              curr?.floor_inventory_qty ??
              0,
            storeSettings,
          })
          valuedStock =
            (acc.valuedStock ?? 0) +
            salePrice *
              (storeSettings?.use_kg_pce === true
                ? stockQuantity
                : stockQuantity * (curr.colisage ?? 1))
          valuedStockSaleIds.push(curr.sale_id ?? "")
          orderValuedStock =
            (acc.orderValuedStock ?? 0) +
            orderPrice *
              (storeSettings?.use_kg_pce === true
                ? stockQuantity
                : stockQuantity * (curr.colisage ?? 1))
          orderValuedStockSaleIds.push(curr.sale_id ?? "")
        }

        const active = "active" in curr ? (curr.active ?? true) : true

        const orderQuantity = active
          ? (updatedReference?.orderInventoryQuantity ??
            curr?.quantity_actual ??
            0)
          : 0

        return {
          totalOrders:
            (acc.totalOrders ?? 0) +
            (storeSettings?.use_kg_pce === true
              ? orderQuantity / (curr.colisage ?? 1)
              : orderQuantity),
          potentialCommandRevenue:
            (acc.potentialCommandRevenue ?? 0) +
            salePrice *
              (storeSettings?.use_kg_pce === true
                ? orderQuantity
                : orderQuantity * (curr.colisage ?? 1)),
          potentialOrderRevenue:
            (acc.potentialOrderRevenue ?? 0) +
            orderPrice *
              (storeSettings?.use_kg_pce === true
                ? orderQuantity
                : orderQuantity * (curr.colisage ?? 1)),
          valuedStock,
          orderValuedStock,
          orderPrice:
            (acc.orderPrice ?? 0) +
            orderPrice *
              (storeSettings?.use_kg_pce === true
                ? orderQuantity
                : orderQuantity * (curr.colisage ?? 1)),
        }
      }, {})
  }, [
    mercurialAndStoreInventories,
    references,
    storeSettings,
    suppliersIds,
    updatedReferences,
  ])

  return potentialRevenue
}
