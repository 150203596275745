import { getUnit } from "../utils/getUnit"
import { StoreReducerState } from "../reducers/storeReducer"
import { MercurialReducerState } from "../reducers/mercurialReducer"
import { addZeroBeforeNumber } from "../utils/addZeroBeforeNumber"

interface ReferenceDetailsProps {
  data: MercurialReducerState["mercurialAndStoreInventories"][number]
  storeCurrency: StoreReducerState["storeCurrency"]
  storeSettings: StoreReducerState["storeSettings"]
  bestMargin: number | null
}

export function ReferenceDetails({
  bestMargin,
  data,
  storeCurrency,
  storeSettings,
}: ReferenceDetailsProps) {
  const margin =
    typeof data.pa === "number" &&
    data.pa > 0 &&
    typeof data.pv === "number" &&
    data.pv > 0
      ? (data.pv - data.pa) / data.pv
      : null

  const formattedMargin =
    margin !== null
      ? new Intl.NumberFormat("fr-FR", {
          maximumFractionDigits: 0,
          style: "percent",
        }).format(margin)
      : ""

  const isBestMargin = bestMargin !== null && margin === bestMargin

  return (
    <div className="flex flex-col text-zinc-500 text-xs md:text-sm font-medium">
      <div className="flex items-center gap-2">
        {data?.order_code !== null && data?.order_code !== "10000000" && (
          <p className="font-light">
            {addZeroBeforeNumber(
              (data?.order_code ?? "").split(/[|;]/).slice(0, 1)[0],
            )}
          </p>
        )}
        {data?.colisage !== null && (
          <p>
            {data?.colisage} {getUnit(data.unit)}
          </p>
        )}
        {data?.origin !== null && <p>{data?.origin}</p>}
        {data?.typology !== null && storeSettings?.typologies !== null && (
          <p>Typologie : {data?.typology}</p>
        )}
      </div>
      <div className="flex items-center gap-2">
        {storeSettings?.view_pa && (
          <p>
            <span className="text-xs">PA : </span>
            {new Intl.NumberFormat("fr-FR", {
              maximumFractionDigits: 2,
              style: "currency",
              currency: storeCurrency ?? "EUR",
            }).format(data?.pa ?? 0)}
          </p>
        )}
        {typeof data.pv === "number" &&
          data.pv > 0 &&
          storeSettings?.view_pv && (
            <p>
              <span className="text-xs">PV :</span>{" "}
              {new Intl.NumberFormat("fr-FR", {
                maximumFractionDigits: 2,
                style: "currency",
                currency: storeCurrency ?? "EUR",
              }).format(data?.pv ?? 0)}
            </p>
          )}
        {storeSettings?.view_pv && storeSettings?.view_pa && (
          <p className={isBestMargin ? "text-green-800 font-bold" : ""}>
            {formattedMargin}
          </p>
        )}
      </div>
    </div>
  )
}
