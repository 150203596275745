import { BaseInventory } from "./BaseInventory"
import { type MercurialReducerState } from "../../reducers/mercurialReducer"
import { InventoryType } from "../../utils/__generated__/graphql"
import { useDispatch, useSelector } from "react-redux"
import { ChangeEvent, useMemo, useRef, useState } from "react"
import { toFixedNumber } from "../../utils/toFixedNumber"
import { StateType } from "../../types"
import { twMerge } from "tailwind-merge"
import { useWindowSize } from "../../hooks/useWindowSize"
import { getQuarterValue } from "../../utils/getQuarterValue"

interface ShelfSizeInputProps {
  data: MercurialReducerState["mercurialAndStoreInventories"][number]
  className?: string
  updateInventory: (value: string) => Promise<void>
}

export function ShelfSizeInput({
  data,
  className,
  updateInventory,
}: ShelfSizeInputProps) {
  const mergedClassName = twMerge(
    "flex items-center gap-1 cursor-pointer",
    className,
  )
  const dispatch = useDispatch()
  const inputRef = useRef<HTMLInputElement>(null)

  const numericKeypadValue = useSelector(
    (state: StateType) => state.userInterfaceReducer.numericPadValue,
  )
  const selectedInventory = useSelector(
    (state: StateType) => state.userInterfaceReducer.selectedInventory,
  )

  const value = toFixedNumber(data.shelf_floor_size ?? 0)

  // Local state to handle input value for better user experience with keyboard on mobile
  const [localValue, setLocalValue] = useState<string>()

  const parsedNumericKeypadValue = useMemo(() => {
    const parsedValue = parseFloat(numericKeypadValue)
    if (
      selectedInventory?.type !== InventoryType.Shelf ||
      selectedInventory?.mercurialeId !== data.mercuriale_id ||
      isNaN(parsedValue)
    )
      return
    return parsedValue
  }, [
    data.mercuriale_id,
    numericKeypadValue,
    selectedInventory?.mercurialeId,
    selectedInventory?.type,
  ])

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.value === "") {
      void updateInventory("0")
      setLocalValue("0")
      return
    }

    const parsedValue = parseFloat(event.target.value)
    if (isNaN(parsedValue)) return

    const stringifiedValue = `${parsedValue}`

    if (event.target.value.endsWith(".") || event.target.value.endsWith(",")) {
      setLocalValue(stringifiedValue + ".")
      void updateInventory(stringifiedValue)
      return
    }

    const integer = stringifiedValue.split(".")[0]

    setLocalValue(integer)
    void updateInventory(stringifiedValue)
  }

  const quarterIcon = getQuarterValue(
    parsedNumericKeypadValue ?? value,
    "w-4 h-4",
  ).icon

  const { isMD } = useWindowSize()

  return (
    <div className={mergedClassName} onClick={() => inputRef.current?.focus()}>
      <p
        className={`text-xs font-medium ${data?.mercuriale_id === selectedInventory?.mercurialeId && selectedInventory?.type === InventoryType.Shelf ? "text-green-500" : "text-gray-400"}`}
      >
        Capacité
      </p>
      <div
        className={`w-10 shadow flex items-center bg-gray-200 rounded ${data?.mercuriale_id === selectedInventory?.mercurialeId && selectedInventory?.type === InventoryType.Shelf ? "ring-2 ring-green-500 text-green-500" : "ring-transparent"} `}
      >
        <input
          ref={inputRef}
          type={isMD ? "" : "number"}
          inputMode={isMD ? "none" : "decimal"}
          className={`h-fit bg-transparent w-full ${quarterIcon ? "text-right" : "text-center"} font-medium border-0 focus:outline-none px-1 py-0 text-xs placeholder:text-inherit`}
          value={
            getQuarterValue(parsedNumericKeypadValue).value ??
            ((value ?? 0) < 0 || value === null
              ? "-"
              : (localValue ?? getQuarterValue(value).value))
          }
          onChange={handleChange}
          onFocus={() => {
            dispatch({
              type: "setSelectedInventory",
              payload: {
                type: InventoryType.Shelf,
                mercurialeId: data?.mercuriale_id ?? undefined,
              },
            })
            // This logic aims to use fresh value when user click on the input and user keyboard to update input
            setLocalValue(inputRef.current?.value)
            if (inputRef.current === null) return
            inputRef.current.placeholder = inputRef.current?.value
          }}
          onBlur={() => {
            setLocalValue(undefined)
          }}
        />
        <div className="flex-1">{quarterIcon}</div>
      </div>
    </div>
  )
}

interface ShelfFloorSizeProps {
  mercurialeId: string
  unit: string
  value: number | undefined
  className?: string
  updateInventory: (value: string) => Promise<void>
  disabled?: boolean
  minimal?: boolean
  isNewReference: boolean
  floorInventoryQuantity: number
}

const ShelfFloorSize = ({
  mercurialeId,
  unit,
  value,
  className,
  updateInventory,
  disabled = false,
  minimal = false,
  isNewReference,
  floorInventoryQuantity,
}: ShelfFloorSizeProps): JSX.Element => {
  return (
    <BaseInventory
      mercurialeId={mercurialeId}
      unit={unit}
      value={value ?? null}
      name={InventoryType.Shelf}
      updateInventory={updateInventory}
      disabled={disabled}
      minimal={minimal}
      className={className}
      isNewReference={isNewReference}
      floorInventoryQuantity={floorInventoryQuantity}
    />
  )
}

export default ShelfFloorSize
