import { FireIcon } from "@heroicons/react/16/solid"
import { Label } from "./Label"
import { ReceiptPercentIcon, SparklesIcon } from "@heroicons/react/24/solid"
import { MercurialReducerState } from "../reducers/mercurialReducer"
import BreakageLabel from "../components/inventory/BreakageLabel"
import { StoreReducerState } from "../reducers/storeReducer"
import { CubeIcon } from "@heroicons/react/24/outline"
import { Delivery } from "./Delivery"

interface BadgesProps {
  expanded?: boolean
  data: MercurialReducerState["mercurialAndStoreInventories"][number]
  isBestSeller?: boolean
  storeSuppliers: StoreReducerState["storeSuppliers"]
  hideBreakageLabel?: boolean
  hide?: boolean
}

export function Badges({
  expanded = false,
  data,
  isBestSeller = false,
  storeSuppliers,
  hideBreakageLabel = false,
  hide = false,
}: BadgesProps) {
  const supplier =
    (storeSuppliers ?? []).length >= 2
      ? storeSuppliers?.find((supplier) => supplier.id === data.supplier_id)
      : undefined
  const breakagePercent =
    typeof data?.breakage_percentage === "number"
      ? Math.round(data.breakage_percentage)
      : 0
  const shouldShowLabels =
    supplier !== undefined ||
    breakagePercent >= 1 ||
    (data?.promotion === true &&
      (typeof data?.message_promotion !== "string" ||
        data.message_promotion.toLowerCase() === "sans offre")) ||
    (typeof data?.message_promotion === "string" &&
      data.message_promotion.toLowerCase() !== "sans offre") ||
    data.mercuriale_name?.toLowerCase().includes("box") === true ||
    expanded

  if (!shouldShowLabels || hide) {
    return <></>
  }

  return (
    <div className={`flex gap-2 ${expanded ? "flex-wrap" : "w-0"}`}>
      {expanded && isBestSeller && (
        <Label
          color="red"
          variant="outline"
          leftIcon={<FireIcon className="w-4 h-4" />}
        >
          Top ventes
        </Label>
      )}
      {expanded && data.new_reference === true && (
        <Label
          color="yellow"
          variant="outline"
          leftIcon={<SparklesIcon className="w-4 h-4" />}
        >
          Nouveau
        </Label>
      )}
      {supplier !== undefined && (
        <Label color="gray">{supplier?.supplier_name}</Label>
      )}
      {!hideBreakageLabel && breakagePercent >= 1 && (
        <BreakageLabel shrinkage={breakagePercent} expanded={expanded} />
      )}
      {/* If 'promotion' is true and 'message_promotion' is null, display 'PROMOTION' */}
      {data?.promotion === true &&
        (typeof data?.message_promotion !== "string" ||
          data.message_promotion.toLowerCase() === "sans offre") && (
          <Label
            color="blue"
            variant="filled"
            leftIcon={<ReceiptPercentIcon className="w-4 h-4" />}
          >
            PROMO
            <span className={`${expanded ? "inline" : "hidden"} md:inline`}>
              TION
            </span>
          </Label>
        )}
      {/* If 'message_promotion' is not null, display the message along with the end date */}
      {typeof data?.message_promotion === "string" &&
        data.message_promotion.toLowerCase() !== "sans offre" && (
          <div className="flex items-center">
            <Label
              color="blue"
              variant="filled"
              leftIcon={<ReceiptPercentIcon className="w-4 h-4" />}
            >
              {data.message_promotion.toUpperCase()}
            </Label>
            {typeof data?.promotion_end_date === "string" && (
              <p className="ml-1 text-cyan-900 text-xxs md:text-xs leading-[18px] font-bold">
                Fin le{" "}
                {new Intl.DateTimeFormat("fr-FR", {
                  day: "2-digit",
                  month: "2-digit",
                }).format(new Date(data?.promotion_end_date))}
              </p>
            )}
          </div>
        )}
      {data.mercuriale_name?.toLowerCase().includes("box") === true && (
        <Label color="gray" leftIcon={<CubeIcon className="w-4 h-4" />}>
          BOX
        </Label>
      )}
      {(typeof data.order_expected_reception_date === "string" ||
        typeof data.mercuriale_reception_date === "string") && (
        <Delivery
          date={
            data.order_expected_reception_date ??
            data.mercuriale_reception_date ??
            ""
          }
          className="lg:hidden"
        />
      )}
    </div>
  )
}
