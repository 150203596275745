import { BaseInventory } from "./BaseInventory"
import { InventoryType } from "../../utils/__generated__/graphql"
import { UserInterfaceReducerState } from "../../reducers/userInterfaceReducer"

interface BaseInventoryProps {
  stock_too_high_flag: boolean | undefined | null
  stock_too_low_flag: boolean | undefined | null
  stock_to_verify_flag: boolean | undefined | null
  mercurialeId: string
  unit: string
  waitedQuantityOrdered?: number
  promoWaitedQuantityOrdered?: number
  value: number | undefined
  className?: string
  updateInventory: (
    value: string,
    selectedInventory?: UserInterfaceReducerState["selectedInventory"],
  ) => Promise<void>
  disabled?: boolean
  minimal?: boolean
  isFirstRow?: boolean
  floorInventoryQuantity: number
}

const BackInventory = ({
  isFirstRow,
  stock_too_high_flag,
  stock_too_low_flag,
  stock_to_verify_flag,
  mercurialeId,
  unit,
  waitedQuantityOrdered,
  promoWaitedQuantityOrdered,
  value,
  className,
  updateInventory,
  disabled = false,
  minimal = false,
  floorInventoryQuantity,
}: BaseInventoryProps): JSX.Element => {
  return (
    <BaseInventory
      isFirstRow={isFirstRow}
      stock_too_high_flag={stock_too_high_flag}
      stock_too_low_flag={stock_too_low_flag}
      stock_to_verify_flag={stock_to_verify_flag}
      mercurialeId={mercurialeId}
      unit={unit}
      waitedQuantityOrdered={waitedQuantityOrdered}
      promoWaitedQuantityOrdered={promoWaitedQuantityOrdered}
      value={value ?? 0}
      name={InventoryType.Back}
      updateInventory={updateInventory}
      disabled={disabled}
      minimal={minimal}
      className={className}
      floorInventoryQuantity={floorInventoryQuantity}
    />
  )
}

export default BackInventory
