import { BaseInventory } from "./BaseInventory"
import { InventoryType } from "../../utils/__generated__/graphql"

interface FloorInventoryProps {
  mercurialeId: string
  unit: string
  value: number | undefined
  className?: string
  updateInventory: (value: string) => Promise<void>
  disabled?: boolean
  minimal?: boolean
  isFirstRow?: boolean
}

const FloorInventory = ({
  mercurialeId,
  unit,
  value,
  className,
  updateInventory,
  isFirstRow,
  disabled = false,
  minimal = false,
}: FloorInventoryProps): JSX.Element => {
  return (
    <BaseInventory
      isFirstRow={isFirstRow}
      mercurialeId={mercurialeId}
      unit={unit}
      value={value ?? 0}
      name={InventoryType.Floor}
      updateInventory={updateInventory}
      disabled={disabled}
      minimal={minimal}
      className={className}
    />
  )
}

export default FloorInventory
