import { createSelector } from "@reduxjs/toolkit"
import { StateType } from "../types"
import { MercurialReducerState } from "../reducers/mercurialReducer"

export function mercurialeStateSelector(state: StateType) {
  return state.mercurialReducer
}

export const filteredMercurialeReducerSelector = createSelector(
  [mercurialeStateSelector],
  (mercurialeState) => {
    const selectedDimMercurialeid = mercurialeState.selectedDimMercurialeId
    if (selectedDimMercurialeid === undefined) {
      return mercurialeState
    }
    const filteredMercurialeInfos =
      mercurialeState.mercurialAndStoreInventories.filter(
        (mercurialAndStoreInventory) =>
          mercurialAndStoreInventory.dim_mercuriale_id ===
          selectedDimMercurialeid,
      )
    const filteredUpdatedReferences = Object.values(
      mercurialeState.updatedReferences,
    ).reduce<MercurialReducerState["updatedReferences"]>(
      (updatedReferences, updatedReference) => {
        if (
          filteredMercurialeInfos.some(
            (filteredMercurialeInfo) =>
              filteredMercurialeInfo.mercuriale_id ===
              updatedReference.mercurialeId,
          )
        ) {
          updatedReferences[updatedReference.mercurialeId] = updatedReference
        }
        return updatedReferences
      },
      {},
    )

    return {
      ...mercurialeState,
      mercurialAndStoreInventories: filteredMercurialeInfos,
      updatedReferences: filteredUpdatedReferences,
    }
  },
)

export const selectedRankingsSelector = createSelector(
  [mercurialeStateSelector],
  (mercurialeState) => {
    const selectedDimMercurialeId = mercurialeState.selectedDimMercurialeId
    if (selectedDimMercurialeId === undefined) {
      return undefined
    }

    return mercurialeState.rankings[selectedDimMercurialeId] ?? undefined
  },
)
