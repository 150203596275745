import { type IconType } from "./types"

export function BackIcon({ className }: IconType): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      width="24"
      className={className}
    >
      <path
        d="M8 13L3 8M3 8L8 3M3 8H13C14.3261 8 15.5979 8.52678 16.5355 9.46447C17.4732 10.4021 18 11.6739 18 13C18 14.3261 17.4732 15.5979 16.5355 16.5355C15.5979 17.4732 14.3261 18 13 18H10.5"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
