import { useMemo, useState } from "react"
import { useGetStoresQuery } from "../../utils/__generated__/graphql"
import { ArrowRightIcon } from "../../ui/icons/ArrowRightIcon"
import { useParams, Link, useSearchParams } from "react-router-dom"
import MinimalLogo from "../../assets/MinimalLogo"
import { usePermissions } from "../../hooks/usePermissions"

export function StoresPage(): JSX.Element {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const { data, loading } = useGetStoresQuery()
  const [searchValue, setSearchValue] = useState("")
  const { isIdaAdmin } = usePermissions()

  const franchiseParam = searchParams.get("franchise")
  const stores = useMemo(() => {
    return (
      data?.getStores?.stores?.filter(
        (store) =>
          store.company_id === params.companyId &&
          store.franchise === franchiseParam &&
          store.store_name.toLowerCase().includes(searchValue.toLowerCase()),
      ) ?? []
    )
  }, [data?.getStores?.stores, franchiseParam, params.companyId, searchValue])

  const companyName = useMemo(() => stores[0]?.company_name, [stores])
  const companyId = useMemo(() => stores[0]?.company_id, [stores])
  const franchise = useMemo(() => stores[0]?.franchise, [stores])

  return (
    <div className="flex flex-col gap-4 py-4 px-2 flex-1">
      <div className="flex flex-col md:flex-row items-center gap-2 md:gap-6 justify-between">
        <div className="flex flex-col md:flex-row items-center gap-2">
          {!loading ? (
            <MinimalLogo
              companyId={companyId}
              franchise={franchise}
              className="w-8 lg:w-8"
            />
          ) : (
            <span className="w-8 h-8 bg-gray-200 animate-pulse rounded-full" />
          )}
          {!loading ? (
            <h1 className="text-2xl font-black uppercase">
              {franchise ?? companyName}
            </h1>
          ) : (
            <span className="w-32 h-8 bg-gray-200 animate-pulse rounded-full" />
          )}
        </div>
        <input
          type="search"
          className="w-full max-w-md focus:ring-green-500 bg-white border-none shadow rounded-md"
          placeholder="Rechercher un nom du magasin"
          onChange={(e) => {
            setSearchValue(e.target.value)
          }}
          value={searchValue}
        />
      </div>
      {!loading ? (
        <p>
          <span className="font-bold">{stores.length}</span> magasin
          {stores.length > 1 && "s"}
        </p>
      ) : (
        <span className="w-24 h-5 bg-gray-200 animate-pulse rounded-full" />
      )}
      {!loading
        ? stores.map((store) => (
            <div
              key={store.id}
              className="w-full bg-white rounded-md shadow p-4 flex flex-col md:flex-row gap-2 justify-between md:items-center"
            >
              <div className="flex flex-col gap-2">
                <h2 className="font-bold text-xl uppercase">
                  {store.store_name}
                </h2>
                {isIdaAdmin && (
                  <p className="text-gray-400 text-sm">{store.id}</p>
                )}
                <p>
                  {store.address} {store.city} {store.country}
                </p>
                <p className="text-sm">
                  <span className="font-black">Code : </span>
                  {store.store_code}
                </p>
              </div>
              <Link
                className="w-full md:w-14 h-14 bg-green-500 hover:bg-green-600 transition text-white flex items-center justify-center rounded-md"
                to={`/stores/${store.id}`}
              >
                <ArrowRightIcon />
              </Link>
            </div>
          ))
        : new Array(3)
            .fill(null)
            .map((_, i) => (
              <div
                key={i}
                className="w-full h-32 bg-gray-200 animate-pulse rounded-md"
              />
            ))}
    </div>
  )
}
