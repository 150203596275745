import { type StoreReducerState } from "../reducers/storeReducer"

interface getTotalStockParams {
  backQuantity: number
  floorQuantity: number
  storeSettings: StoreReducerState["storeSettings"]
}

export function getTotalStock({
  backQuantity,
  floorQuantity,
  storeSettings,
}: getTotalStockParams): number {
  const positiveStockQuantity = Math.max(
    backQuantity +
      (storeSettings?.separate_floor_inventory === true ? floorQuantity : 0),
    0,
  )
  return positiveStockQuantity
}
